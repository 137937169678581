import React, { Component } from 'react';
import { Column, Row } from 'simple-flexbox';
import { Panel, Button } from "react-bootstrap";
import { NavLink } from 'react-router-dom';

import Alert from "@sm/components/custom/Alert";
import CustomButton from "@sm/components/custom/Button";
import Form from "@sm/core/Form";
import Multiselect from '@sm/components/custom/customMultiselect';
import Spinner from '@sm/components/Spinner';

import '@assets/css/userManagement.css';
import UserManagementIcon from '@assets/images/user-management.png';

import localization from '@assets/lang/language';
import { crudActions } from "@sm/services/crudActions";
import { FETCH_PERMISSIONS } from '@sm/actions/types';
const store =  require('@sm/reducers/index');

class AgentManagement extends Component {
  state = {
    filterOn: false,

    userStatus: [],
    filteredUsers: [],

    controls: new Form({
      agentsStatus: [],
      name: "",
      email: ""
    }, [{
      name: "agentsStatus",
      type: "isArray",
      rules: {
        required: true,
        min: 1
      }
    }, {
      name: "name",
      type: "isString",
      rules: {
        required: false,
      }
    }, {
      name: "email",
      type: "isString",
      rules: {
        required: false,
        min: 1
      }
    }]),

    access: [],

    showAlert: false,
    alertType: "success",
    alertMessage: "",
    usersLoading: false,
    loadingLookups: true,
    searchClicked: false,

    //temporary
    currentLanguage: "en"
  };

  subscribeFunction = null;

  componentDidMount() {
    const { controls } = this.state;
    const storeState = store.default.getState().authReducer;
    if (storeState.access) {
      this.setState({
        access: storeState.access
      });
    }

    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().authReducer;

      if (state.userUpdate === FETCH_PERMISSIONS) {
        this.setState({
          access: state.access
        });
      }
    });

    crudActions.get('v1/users/management/lookups').then(
      (lookups) => {
        if (lookups) {
          const userStatus = lookups.userStatus;

          const updateObject = {
            userStatus: userStatus,
            loadingLookups: false
          };

          if (storeState.roleId === "MASTER_TECH" || storeState.roleId === "MASTER_ADMIN") {
            updateObject.controls = Object.assign(controls, {
              agentsStatus: [userStatus.find(elem => elem.value === "ACTIVE").value]
            });
          }

          this.setState(updateObject);
        }
      }
    );
  };

  componentWillUnmount() {
    if (this.subscribeFunction) {
      this.subscribeFunction();
    }
  };

  onValueChange = (event, field) => {
    const { controls, searchClicked } = this.state;
    if (!event) {
      controls[field] = event;
    } else if (event.target) {
      controls[field] = event.target.value;
    } else if (event.value) {
      controls[field] = event.value;
    } else {
      controls[field] = event.map(elem => elem.value);
    }

    if (searchClicked) {
      controls.isFormValid();
    }

    this.setState({
      controls
    });
  };

  onChangeFilter = () => {
    this.filterAgents();
  };

  filterAgents = () => {
    const { controls } = this.state;

    const isControlsValid = controls.isFormValid();
    this.setState({
      controls,
      usersLoading: isControlsValid,
      filterOn: true,
      searchClicked: true
    });

    const controlsData = controls.data();

    if (isControlsValid) {
      (crudActions.post('v1/agent/find', controlsData)).then(
        (users) => {
          if (users) {
            this.setState({
              filteredUsers: users,
              usersLoading: false
            });
          }
        }
      ).catch(
        err => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message,
              usersLoading: false
            });
          }
        }
      );
    }
  };

  mapSelectedItems = (type, lookupsName) => {
    const field = this.state.controls[type];
    const lookup = this.state[lookupsName];
    if (!field || !lookup) {
      return [];
    }

    return field.map(elem => {
      return lookup.find(lookupElem => elem === lookupElem.value);
    });
  };

  localizeItems = (items, fieldName) => {
    const { currentLanguage } = this.state;
    const language = localization[currentLanguage];

    const field = language[fieldName];
    return items.map(elem => {
      return { ...elem, label: field[elem.label] };
    });
  };

  userActivation = (activateOrDeactivate, userId) => {
    crudActions.put(`v1/users/management/user/${userId}/${activateOrDeactivate ? "reactivate" : "deactivate"}`).then(
      () => {
        this.setState({
          showAlert: true,
          alertType: "success",
          alertMessage: `Agent successfully ${activateOrDeactivate ? "activated." : "deactivated."}`
        });
      }
    ).catch(
      err => {
        if (err && err.message) {
          this.setState({
            showAlert: true,
            alertType: "error",
            alertMessage: err.message,
          });
        }
      }
    );
  };

  onConfirm = () => {
    this.setState({
      showAlert: false,
      alertType: "success",
      alertMessage: ""
    }, this.state.showAlert && this.state.alertType === "success" ? this.filterAgents : null);
  };

  checkPageAccess = (permissionName) => {
    const { access } = this.state;
    const foundPermission = access.find(elem => elem.permission === permissionName);
    if (!foundPermission) {
      return false;
    }

    return foundPermission.state;
  };

  render() {
    const { controls, filterOn, filteredUsers, usersLoading, loadingLookups,
      userStatus, showAlert, alertType, alertMessage } = this.state;

    return (
      <Row flexGrow={ 1 } className="module user-management" vertical='start'>
        <Column flexGrow={ 1 }>
          <Row className="header" flexGrow={ 1 } horizontal='space-between' vertical='center'>
            <Column>
              <Row horizontal='center' vertical='center' style={ {paddingLeft: 15} }>
                <img src={ UserManagementIcon } alt="" style={ {marginRight: 10} }/>
                Agent Management
              </Row>
            </Column>
            <Column horizontal='end'>
              <Row horizontal='end' vertical='center'>
                { this.checkPageAccess("USER_EDIT") && <Column horizontal='end' style={ {paddingRight: 15} }>
                  <Row horizontal='end' vertical='center' style={ {color: '#ccc', fontSize: '12px'} }>
                    <NavLink  to={ {
                      pathname: `/add-user`,
                      state: { params: { isAgent: true } }
                    } } className="btn add-deposit-button">
                      CREATE NEW AGENT
                    </NavLink>
                  </Row>
                </Column> }
              </Row>
            </Column>
          </Row>
          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
            <Column flexGrow={ 1 } vertical='start' className="panel-block">
              <Panel>
                <Panel.Heading>
                  <Panel.Title>
                    AGENT SEARCH
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  { loadingLookups ? <div style={ { width: "100%", height: "200px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                    <Spinner smallContainer={ true } />
                  </div> : <div className="panel-content" style={ {overflow: 'unset'} }>
                    <Row flexGrow={ 1 } wrap={ true } horizontal='center' vertical='center' style={ { width: '100%' } }>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' alignSelf='start'>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Status </label>
                          <Multiselect
                            isError={ controls.errors.has("agentsStatus") }
                            selectedItems={ this.mapSelectedItems("agentsStatus", "userStatus") }
                            items={ userStatus }
                            type={ "agentsStatus" }
                            onChange={ this.onValueChange }/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Name </label>
                          <input
                            className={ "form-control " + (controls.errors.has("name") ? "error-field" : "") }
                            value={ controls.name || '' }
                            onChange={ (value) => this.onValueChange(value, 'name') }
                          />
                        </Column>
                      </Row>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' alignSelf='end'>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Email/Username </label>
                          <input
                            className={ "form-control " + (controls.errors.has("email") ? "error-field" : "") }
                            value={ controls.email || '' }
                            onChange={ (value) => this.onValueChange(value, 'email') }
                          />
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='end' className="input-column fullwidth-button">
                          <CustomButton
                            title="Search"
                            type="submit"
                            style={ { width: "100%", outline: usersLoading ? "5px auto -webkit-focus-ring-color" : "0" } }
                            onClick={ () => this.onChangeFilter() }
                          />
                        </Column>
                      </Row>
                    </Row>
                  </div> }
                </Panel.Body>
              </Panel>
              { filterOn && <Panel>
                <Panel.Heading>
                  <Panel.Title>
                    AGENTS
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  { usersLoading ? <div style={ { width: "100%", height: "200px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                    <Spinner smallContainer={ true } />
                  </div> : <div className="panel-content" style={ {overflow: 'unset'} }>
                    <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' className="user-management-wrapper">
                      <table className="table table-striped user-management-table">
                        <thead>
                          <tr>
                            <th>
                              Full Name
                            </th>
                            <th>
                              Email/Username
                            </th>
                            { this.checkPageAccess("USER_VIEW") && <th className="text-center">
                              View
                            </th> }
                            { this.checkPageAccess("USER_EDIT") && <th className="text-center">
                              Edit
                            </th> }
                            { this.checkPageAccess("USER_EDIT") && <th className="text-center">
                              Activation
                            </th> }
                          </tr>
                        </thead>
                        <tbody>
                          {
                            filteredUsers.map((user) => {
                              return <tr key={ user.id }>
                                <td>
                                  {user.name}
                                </td>
                                <td>
                                  {user.email}
                                </td>
                                { this.checkPageAccess("USER_VIEW") && <td>
                                  <NavLink to={ `/view-user/${user.id}` } className="btn view-user">
                                    View
                                  </NavLink>
                                </td> }
                                { this.checkPageAccess("USER_EDIT") && <td>
                                  <NavLink to={ `/edit-user/${user.id}` } className="btn edit-user">
                                    Edit
                                  </NavLink>
                                </td> }
                                { this.checkPageAccess("USER_EDIT") && <td>
                                  <Button
                                    className={ `btn ${user.active === "ACTIVE" ? "deactivate-user" : "activate-user"}` }
                                    onClick={ () => this.userActivation(user.active === "DEACTIVATED", user.id) }
                                  >
                                    { user.active === "ACTIVE" ? "Deactivate" : "Reactivate" }
                                  </Button>
                                </td> }
                              </tr>;
                            })
                          }
                        </tbody>
                      </table>
                    </Row>
                  </div> }
                </Panel.Body>
              </Panel> }
            </Column>
          </Row>
        </Column>

        {showAlert && (
          <Alert
            show={ showAlert }
            title={ alertType }
            type={ alertType }
            text={ alertMessage }
            confirmButtonColor="#187EED"
            onConfirm={ this.onConfirm }
          />
        )}

      </Row>

    );
  }
}

export default AgentManagement;
