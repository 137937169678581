export const FETCH_USER = 'fetch-user';
export const FETCH_PERMISSIONS = 'fetch-permissions';
export const SHOW_MESSAGE = 'show-message';
export const DASHBOARD_SELLS_EVENT = 'dashboard-sells-event';
export const DASHBOARD_SETTLEMENTS = 'dashboard-settlements';
export const DASHBOARD_PURCHASES_EVENT = 'dashboard-deposits-event';
export const DASHBOARD_PURCHASE_COUNTRIES_EVENT = 'dashboard-purchase-countries-event';
export const DASHBOARD_FTD_EVENT = 'dashboard-ftd-event';
export const DASHBOARD_REVENUE_EVENT = 'dashboard-revenue-event';
export const DASHBOARD_USERS_EVENT = 'dashboard-users-event';
export const DASHBOARD_TRANSFER_EVENT = 'dashboard-transfer-event';
export const DASHBOARD_CUSTOMER_BALANCES_EVENT = 'dashboard-customer-balances-event';
export const DASHBOARD_PIE_EVENT = 'dashboard-pie-event';
export const DASHBOARD_HISTOGRAM_EVENT = 'dashboard-histogram-event';
export const DASHBOARD_WITHDRAWALS_EVENT = 'dashboard-withdrawals-countries-event';
export const DASHBOARD_PAYOUT_EVENT = 'dashboard-payout-event';
export const DASHBOARD_PAYOUT_COUNTRIES_EVENT = 'dashboard-payout-countries-event';
export const DASHBOARD_DEPOSITS_PER_MIDS = 'dashboard-deposits-per-mids';
export const DASHBOARD_PAYOUTS_PER_MIDS = 'dashboard-payouts-per-mids';
export const DASHBOARD_AMOUNTS_PER_MID = 'dashboard-amounts-per-mid';