import React, { Component } from 'react';
import { Column, Row } from 'simple-flexbox';

import ManageUser from '@modules/userManagement/ManageUser';

class EditUser extends Component {
  state = {};

  render() {
    return <Row flexGrow={ 1 } className='module apidata editUser' vertical='start'>
      <Column flexGrow={ 1 }>
        <ManageUser userId={ this.props.match.params.userId } viewOrEdit={ 2 }/>
      </Column>
    </Row>;
  }
}

export default EditUser;