import React, {Component} from 'react';

import { Redirect } from 'react-router-dom';
import { Row, Column } from 'simple-flexbox';
import { Button, Modal } from "react-bootstrap";
import { crudActions } from "@sm/services/crudActions";

import Alert from "@sm/components/custom/Alert";
import Form from "@sm/core/Form";
import SignupImages from "@modules/signup/signup-images";
import SignupTexts from "@modules/signup/signup-texts";
import SignUpFooter from '@modules/signup/SignUpFooter';

import NotChecked from "@assets/images/not-checked.png";
import Checked from "@assets/images/checked.png";
import '@assets/css/SignUp.css';

import { signUpApiKey } from "@sm/config";
import { PASSWORD_REGEX, TELEGRAM_USERNAME_REGEX } from "@sm/constants";

class SignUp extends Component {
  state = {
    fields: {
      agent: [{
        value: "email",
        label: "Email",
      }, {
        value: "agentPassword",
        label: "Password",
        type: "password"
      }, {
        value: "confirmPassword",
        label: "Confirm Password",
        type: "password"
      }, {
        value: "contactPerson",
        label: "Contact Person"
      }, {
        value: "skype",
        label: "Skype"
      }, {
        value: "telegram",
        label: "Telegram Username"
      }, {
        value: "phone",
        label: "Phone"
      }],
      merchant: [{
        value: "brandName",
        label: "Entity"
      }, {
        value: "contactPerson",
        label: "Contact Person"
      }, {
        value: "email",
        label: "Email",
      }, {
        value: "skype",
        label: "Skype"
      }, {
        value: "telegram",
        label: "Telegram Username"
      }, {
        value: "phone",
        label: "Phone"
      }],
      "white-label": [{
        value: "contactPerson",
        label: "Contact Person"
      }, {
        value: "email",
        label: "Email",
      }, {
        value: "skype",
        label: "Skype"
      }, {
        value: "telegram",
        label: "Telegram Username"
      }, {
        value: "phone",
        label: "Phone"
      }],
      psp: []
    },

    signUpForm: {
      "white-label": new Form({
        contactPerson: "",
        email: "",
        skype: "",
        telegram: "",
        phone: ""
      }, [{
        name: "contactPerson",
        type: "isString",
        rules: {
          required: true
        }
      }, {
        name: "email",
        type: "isEmail",
        rules: {
          required: true
        }
      }, {
        name: "skype",
        type: "isString",
        rules: {
          required: false
        }
      }, {
        name: "telegram",
        type: "isPattern",
        rules: {
          required: false,
          pattern: TELEGRAM_USERNAME_REGEX,
          customErrorMessage: "You can use letters, numbers and underscores. Minimum length is 5 characters."
        }
      }, {
        name: "phone",
        type: "isString",
        rules: {
          required: false
        }
      }]),
      merchant: new Form({
        brandName: "",
        contactPerson: "",
        email: "",
        skype: "",
        telegram: "",
        phone: ""
      }, [{
        name: "brandName",
        type: "isString",
        rules: {
          required: true
        }
      }, {
        name: "contactPerson",
        type: "isString",
        rules: {
          required: true
        }
      }, {
        name: "email",
        type: "isEmail",
        rules: {
          required: true
        }
      }, {
        name: "skype",
        type: "isString",
        rules: {
          required: false
        }
      }, {
        name: "telegram",
        type: "isPattern",
        rules: {
          required: false,
          pattern: TELEGRAM_USERNAME_REGEX,
          customErrorMessage: "You can use letters, numbers and underscores. Minimum length is 5 characters."
        }
      }, {
        name: "phone",
        type: "isString",
        rules: {
          required: false
        }
      }]),
      agent: new Form({
        contactPerson: "",
        email: "",
        skype: "",
        telegram: "",
        phone: "",
        agentPassword: ""
      }, [{
        name: "contactPerson",
        type: "isString",
        rules: {
          required: true
        }
      }, {
        name: "email",
        type: "isEmail",
        rules: {
          required: true
        }
      }, {
        name: "agentPassword",
        type: "isPattern",
        rules: {
          required: true,
          pattern: PASSWORD_REGEX,
          customErrorMessage: "Password must contain at least 1 lowercase alphabetical character, 1 uppercase alphabetical character, 1 numeric character, 1 special character and must be 8 characters or longer"
        }
      }, {
        name: "skype",
        type: "isString",
        rules: {
          required: false
        }
      }, {
        name: "telegram",
        type: "isPattern",
        rules: {
          required: false,
          pattern: TELEGRAM_USERNAME_REGEX,
          customErrorMessage: "You can use letters, numbers and underscores. Minimum length is 5 characters."
        }
      }, {
        name: "phone",
        type: "isString",
        rules: {
          required: false
        }
      }]),
      psp: new Form({}, [])
    },

    registrationType: "agent",
    confirmPassword: "",
    confirmPasswordTouched: false,
    registrationOpened: false,
    showRegisterModal: false,
    showAboutModal: false,
    showAlert: false,
    alertType: "success",
    alertMessage: ""
  };

  burgerNode = null;
  node = null;

  getCookie = (cookieName) => {
    const match = document.cookie.match(new RegExp('(^| )' + cookieName + '=([^;]+)'));
    if (match) return match[2];
  };

  handleClick = (e) => {
    if ((this.node && this.node.contains(e.target)) || (this.burgerNode && this.burgerNode.contains(e.target))) {
      return;
    }

    this.handleClickOutside();
  };

  handleClickOutside = () => {
    if (this.state.isBurgerOpen) {
      this.setState({
        isBurgerOpen: false
      });
    }
  };

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
    const { fields, signUpForm } = this.state;
    const product = this.props.match.params.product;

    if (!fields[product] || !signUpForm[product]) {
      window.location.href = "/sign-up/agent";
    }

    const cookiesMap = {
      agent: "agnt",
      "white-label": "wl",
      merchant: "mcnt",
      psp: "psp"
    };

    const date = new Date();
    date.setDate(date.getDate() + 30);
    if (this.getCookie("p")) {
      document.cookie = "p=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }
    document.cookie = `p=${cookiesMap[product]};expires=${date}; path=/;`;

    this.setState({
      registrationType: product
    });
  };

  openRegistration = () => {
    this.setState({
      registrationOpened: !this.state.registrationOpened
    });
  };

  onValueChange = (event, fieldName) => {
    const { signUpForm, registrationType } = this.state;
    const selectedSignUpForm = signUpForm[registrationType];
    selectedSignUpForm[fieldName] = event.target.value;
    selectedSignUpForm.isFormFieldValid(fieldName, null, true);
    this.setState({
      signUpForm
    });
  };

  onConfirmPasswordChange = (event) => {
    this.setState({
      confirmPassword: event.target.value,
      confirmPasswordTouched: true
    });
  };

  onConfirm = () => {
    const isSuccess = this.state.showAlert && this.state.alertType === "success";
    this.setState({
      showAlert: false,
      alertType: "success",
      alertMessage: ""
    }, () => {
      isSuccess && this.handleCloseModal();
    });
  };

  onRegisterClick = () => {
    const { signUpForm, registrationType } = this.state;
    let selectedSignUpForm = signUpForm[registrationType];
    const isValid = selectedSignUpForm.isFormValid();
    this.setState({
      signUpForm
    });

    const fieldRulePassword = selectedSignUpForm.fieldRules.find(field => field.name === "agentPassword");
    const agentPasswordValidation = fieldRulePassword ? this.arePasswordsIdentical() : true;

    if (isValid && agentPasswordValidation) {
      const data = selectedSignUpForm.data();
      crudActions.post(`v1/approval/requests/create`, data, { apikey: signUpApiKey }).then(
        () => {
          selectedSignUpForm = Object.assign(selectedSignUpForm, selectedSignUpForm.originalData);
          this.setState({
            showAlert: true,
            alertType: "success",
            alertMessage: "Your account is pending review. You will be contacted shortly.",
            confirmPassword: "",
            confirmPasswordTouched: false,
            signUpForm
          });
        }
      ).catch(
        err => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message
            });
          }
        }
      );
    }
  };

  arePasswordsIdentical = () => {
    const { signUpForm, confirmPassword, registrationType } = this.state;
    const agentPassword = signUpForm[registrationType].agentPassword;

    if (!confirmPassword) {
      return false;
    }

    return agentPassword === confirmPassword;
  };

  onRegister = () => {
    this.setState({
      showRegisterModal: true,
      isBurgerOpen: false
    });
  };

  onAbout = () => {
    this.setState({
      showAboutModal: true,
      isBurgerOpen: false
    });
  };

  handleCloseModal = () => {
    this.setState({
      showRegisterModal: false,
      showAboutModal: false
    });
  };

  getTexts = (section) => {
    return <div>
      {
        section.map((elem, i) => {
          return elem.type === "h1" ? <h1 key={ i } className={ elem.className || "" }> { elem.text } </h1> :
            elem.type === "h2" ? <h2 key={ i } className={ elem.className || "" }> { elem.text } </h2> :
              elem.type === "h3" ? <h3 key={ i } className={ elem.className || "" }> { elem.text } </h3> :
                elem.type === "h4" ? <h4 key={ i } className={ elem.className || "" }> { elem.text } </h4> :
                  elem.type === "h5" ? <h5 key={ i } className={ elem.className || "" }> { elem.text } </h5> :
                    elem.type === "p" ? <p key={ i } className={ elem.className || "" }> { elem.text } </p> :
                      elem.type === "ul" ? <ul key={ i }>
                        {
                          elem.text.map((listItem, index) =>
                            <li key={ index }> { listItem } </li>
                          )
                        }
                      </ul> :
                        elem.type === "ol" ? <ol key={ i } type={ elem.kind }>
                          {
                            elem.text.map((listItem, index) =>
                              <div key={ index }>
                                <li> { listItem.header } </li>
                                <h4> { listItem.paragraph } </h4>
                              </div>
                            )
                          }
                        </ol> : <span/>;

        })
      }
    </div>;
  };

  onBurgerClick = () => {
    this.setState({
      isBurgerOpen: !this.state.isBurgerOpen
    });
  };

  render() {
    const { signUpForm, fields, showAlert, alertType, alertMessage, showRegisterModal, confirmPassword,
      confirmPasswordTouched, registrationType, showAboutModal, isBurgerOpen } = this.state;

    const selectedFields = fields[registrationType];
    const selectedForm = signUpForm[registrationType];

    if (!selectedFields || !selectedForm) {
      return <Redirect to='/sign-up/agent'/>;
    }

    const images = SignupImages[registrationType];
    const texts = SignupTexts[registrationType];

    return (
      <Column className={ `main-sign-up ${registrationType}` }>
        <Column horizontal="center" vertical="center" flexGrow={ 1 } className="signup-info">
          <Row horizontal="space-between" vertical="start" className="signup-info-row header-row">
            <img src={ images.logo } alt="logo" className="logo"/>
            <Row horizontal='center' className="navigation-bar">
              <span className="signup-rights" onClick={ () => this.onAbout() }> ABOUT </span>
              <span className="signup-rights" onClick={ () => this.onRegister() }> REGISTER </span>
              <span className="signup-rights"> CONTACT </span>
            </Row>
            <div ref={ node => this.burgerNode = node } className={ "signup-burger-container " + (isBurgerOpen ? "signup-burger-change" : "") }  onClick={ this.onBurgerClick }>
              <div className="signup-burger-bar1"></div>
              <div className="signup-burger-bar2"></div>
              <div className="signup-burger-bar3"></div>
            </div>

            {
              isBurgerOpen && <div ref={ node => this.node = node } className="signup-burger-content">
                <div className="link-item-container">
                  <span className="link-item" onClick={ () => this.onAbout() }> ABOUT </span>
                </div>
                <div className="link-item-container">
                  <span className="link-item" onClick={ () => this.onRegister() }> REGISTER </span>
                </div>
                <div className="link-item-container">
                  <span className="link-item"> CONTACT </span>
                </div>
              </div>
            }
          </Row>
          <Row horizontal="start" vertical="start" flexGrow={ 1 } className="signup-info-row data-row">
            <Column horizontal="start" vertical="start" className="data-column">
              { this.getTexts(texts.firstSection) }
              <Button
                className="signup-action-button btn"
                onClick={ () => this.onRegister() }>
                SIGN UP NOW
              </Button>
            </Column>
            <Column className="data-column">
              { images.scheme.isVideo ?
                <video src={ images.scheme.data } playsInline autoPlay muted loop/> :
                <img src={ images.scheme } alt="backoffice"/> }
              { images.app && <img className="app-image" src={ images.app } alt="app"/> }
            </Column>
          </Row>
        </Column>
        <Column horizontal="center" vertical="center" flexGrow={ 1 } className="signup-general-info">
          <Row horizontal="start" vertical="center" flexGrow={ 1 } className="signup-general-info-row data-row">
            <Column className="data-column">
              <img src={ images.backoffice } alt="backoffice"/>
            </Column>
            <Column className="data-column">
              { this.getTexts(texts.secondSection) }
              <Button
                className={ `signup-button-${registrationType}` }
                onClick={ () => this.onRegister() }>
                SIGN UP NOW
              </Button>
            </Column>
          </Row>
        </Column>
        <Column horizontal="center" vertical="center" flexGrow={ 1 } className="signup-action-div">
          { this.getTexts(texts.thirdSection) }
          <Button
            className="signup-bottom-button"
            onClick={ () => this.onRegister() }>
            SIGN UP NOW
          </Button>
        </Column>
        <SignUpFooter />
        
        <Modal
          show={ showRegisterModal }
          onHide={ this.handleCloseModal }>
          <Modal.Body>
            <Row flexGrow={ 1 } horizontal="start" vertical="start" style={ { width: '100%' } }>
              <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="modal-column fields">
                <h3 className="register-header"> FILL IN THE FIELDS AND GET MORE INFORMATION </h3>
                <form autoComplete="off">
                  <input type="agentPassword" autoComplete="new-agentPassword" style={ { display: "none" } }/>
                  {
                    selectedFields.map(field => {
                      return field.value === "confirmPassword" ? <div key={ field.value } className="signup-field">
                        <label className="signup-field-label"> { field.label } </label>
                        <input
                          type={ field.type }
                          autoComplete="off"
                          className={ "form-control signup-input " + (!this.arePasswordsIdentical() && confirmPasswordTouched ? 'error-field' : confirmPassword ? "success-field" : "") }
                          value={ confirmPassword || '' }
                          onChange={ (value) => this.onConfirmPasswordChange(value) }
                        />
                        <img src={ this.arePasswordsIdentical() && confirmPasswordTouched ? Checked : NotChecked }
                          alt="checkmark" className="signup-field-checkmark"/>
                        { !this.arePasswordsIdentical() && confirmPasswordTouched && <p className="error-message">
                          { !confirmPassword ? "Confirmation is required."  : "Passwords are not identical." } </p>}
                      </div> : <div key={ field.value } className="signup-field">
                        <label className="signup-field-label"> { field.label } </label>
                        <input
                          type={ field.type || "text" }
                          value={ selectedForm[field.value] || "" }
                          autoComplete={ field.value === "agentPassword" ? "off" : "" }
                          className={ `form-control signup-input ${selectedForm.errors.has(field.value) ? "error-field" : selectedForm[field.value] ? "success-field" : ""}` }
                          onChange={ (event) => this.onValueChange(event, field.value) }/>
                        { selectedForm.errors.has(field.value) && (field.value === "agentPassword" || field.value === "telegram")  &&
                        	<p className="error-message">
                        	  { selectedForm.errors.get(field.value) }
                        	</p>
                        }
                        <img src={ !selectedForm.errors.has(field.value) && selectedForm[field.value] ? Checked : NotChecked }
                          alt="checkmark" className="signup-field-checkmark"/>
                      </div>;
                    })
                  }
                  <Button
                    className={ `signup-field signup-button-${registrationType}` }
                    onClick={ () => this.onRegisterClick() }>
                      GET MORE INFO
                  </Button>
                </form>
              </Column>
              <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="modal-column backgrounded">
                <img src={ images.backgroundModal } alt="backoffice"/>
              </Column>
            </Row>
            <Row flexGrow={ 1 } horizontal='center' vertical='center' className="close-modal">
              <label onClick={ () => this.handleCloseModal() }> Close window </label>
            </Row>
          </Modal.Body>
        </Modal>

        <Modal
          show={ showAboutModal }
          onHide={ this.handleCloseModal }>
          <Modal.Body>
            <Row flexGrow={ 1 } horizontal="start" vertical="start" style={ { width: '100%' } }>
              <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="modal-column fields">
                { this.getTexts(texts.fourthSection) }
              </Column>
              <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="modal-column backgrounded">
                <img src={ images.backgroundModal } alt="backoffice"/>
              </Column>
            </Row>
            <Row flexGrow={ 1 } horizontal='center' vertical='center' className="close-modal">
              <label onClick={ () => this.handleCloseModal() }> Close window </label>
            </Row>
          </Modal.Body>
        </Modal>

        {showAlert && (
          <Alert
            show={ showAlert }
            title={ alertType === "success" ? "Thank you for signing up!" : alertType }
            type={ alertType }
            text={ alertMessage }
            confirmButtonColor="#187EED"
            onConfirm={ this.onConfirm }
          />
        )}

      </Column>
    );
  }
}

export default SignUp;
