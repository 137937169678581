import React, { Component } from 'react';
import { Column, Row } from 'simple-flexbox';
import { Panel } from "react-bootstrap";
import { NavLink } from 'react-router-dom';
import * as XLSX from 'xlsx';

import Alert from "@sm/components/custom/Alert";
import CustomButton from "@sm/components/custom/Button";
import Form from "@sm/core/Form";
import Select from "@sm/components/custom/Select";
import Multiselect from "@sm/components/custom/customMultiselect";
import Spinner from '@sm/components/Spinner';

import '@assets/css/editClient.css';
import '@assets/css/bankCodes.css';
import MerchantsIcon from '@assets/images/merchants.png';

import { FETCH_PERMISSIONS } from '@sm/actions/types';
import { crudActions } from "@sm/services/crudActions";
const store =  require('@sm/reducers/index');

class BankCodes extends Component {
  state = {
    controls: new Form({
      whiteLabelIds: [],
      pspId: "",
      currency: ""
    }, [{
      name: "whiteLabelIds",
      type: "isArray",
      rules: {
        required: true
      }
    }, {
      name: "pspId",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "currency",
      type: "isString",
      rules: {
        required: true
      }
    }]),

    companies: [],
    companiesPsps: {},
    currencies: [],
    access: [],
    roleId: "",

    banks: [],
    systemBankCodes: [],
    bankCodeForms: [],
    bankCodeFormRules: [{
      name: "bankName",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "bankCode",
      type: "isString",
      rules: {
        required: true
      }
    }],

    isLoading: true,
    isLoadingBankCodes: false,
    isSecondPanelVisible: false,
    showAlert: false,
    alertType: "success",
    alertMessage: ""
  };

  componentDidMount() {
    const storeState = store.default.getState().authReducer;
    if (storeState.access) {
      const roleId = storeState.roleId;
      this.setState({
        access: storeState.access,
        roleId
      });
    }

    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().authReducer;

      if (state.userUpdate === FETCH_PERMISSIONS) {
        this.setState({
          access: state.access
        });
      }
    });

    crudActions.get(`v1/companies/clients`)
      .then(
        (clients) => {
          if (clients) {
            const companiesList = clients.map(elem => elem.company);
            companiesList.sort((elemA, elemB) => elemA.label.localeCompare(elemB.label));
            this.setState({
              companies: companiesList,
              companiesPsps: clients.reduce((accumulator, currentElem) => {
                accumulator[currentElem.company.value] = currentElem.psps.map(elem => elem);
                return accumulator;
              }, {}),
              isLoading: false
            }, () => {
              const { companies } = this.state;
              if (companies.length) {
                this.onValueChange(companies, 'whiteLabelIds');
              }
            });
          }
        }
      );
  };

  loadCompaniesData = (whiteLabelIds) => {
    if (whiteLabelIds.length) {
      Promise.all(
        whiteLabelIds.map(wlId => crudActions.get(`v1/adminpanel/company/${wlId}`))
      ).then((data) => {
        if (data) {
          let currencies = [];
          for (let i = 0; i < data.length; i++) {
            currencies = currencies.concat(data[i].storedLookups.currency || []);
          }
          if (currencies.length) {
            currencies.sort((elemA, elemB) => elemA.localeCompare(elemB));
            const unique = [...new Set(currencies)];
            const transformed = unique.map((item) => ({ label: item, value: item }));
            
            this.setState({
              currencies: transformed
            });
          }
          
        }
      });
    } else {
      this.setState({
        currencies: [],
        controls: Object.assign(this.state.controls, {
          currency: "",
          pspId: ""
        })
      });
    }
  };

  onValueChange = (event, field) => {
    const { controls } = this.state;
    if (!event) {
      controls[field] = event;
    } else if (event.target) {
      controls[field] = event.target.value;
    } else if (event.value) {
      controls[field] = event.value;
    } else {
      controls[field] = event.map(elem => elem.value);
    }

    if (field === "whiteLabelIds") {
      this.loadCompaniesData(controls[field]);
    }

    this.setState({
      controls
    });
  };

  checkPageAccess = (permissionName) => {
    const { access } = this.state;
    const foundPermission = access.find(elem => elem.permission === permissionName);
    if (!foundPermission) {
      return false;
    }

    return foundPermission.state;
  };

  mapSelectedItems = (type, lookups) => {
    const field = this.state.controls[type];
    const lookup = this.state[lookups];
    return field.map(elem => {
      return lookup.find(lookupElem => elem === lookupElem.value);
    });
  };

  getWhiteLabelsProviders = () => {
    const { controls, companiesPsps } = this.state;
    let providers = [];

    if (controls.whiteLabelIds.length) {
      for (let i = 0; i < controls.whiteLabelIds.length; i++) {
        providers = providers.concat(companiesPsps[controls.whiteLabelIds[i]]);
      }
    }
    if (providers.length) {
      providers.sort((elemA, elemB) => elemA.label.localeCompare(elemB.label));
      const unique = [...new Map(providers.map(item => [item.value, item])).values()];
      return unique;
    }
    return providers;
  };

  filterBanks = (current) => {
    const { banks } = this.state;

    const filtered = banks.filter(bank => this.isBankSelected(bank.value, current));

    return filtered;
  };

  isBankSelected = (bank, currentBank) => {
    const { bankCodeForms } = this.state;
    if (bankCodeForms.length) {
      for (let i = 0; i < bankCodeForms.length; i++) {
        if (bankCodeForms[i].bankName === bank && bankCodeForms[i].bankName !== currentBank) {
          return false;
        }
      }
    }
    return true;
  };

  onFilterBankCodes = () => {
    const { controls } = this.state;
    const isValidForm = controls.isFormValid();

    this.setState({
      controls,
      isLoadingBankCodes: isValidForm,
      isSecondPanelVisible: isValidForm,
      bankCodeForms: []
    });
    if (isValidForm) {
      const data = {
        whiteLabelIds: controls.whiteLabelIds,
        pspId: controls.pspId,
        currency: controls.currency
      };
      crudActions.post('v1/banks/search', data)
        .then(response => {
          if (response && response.data) {
            const bankCodesList = [];
            if (response.data.length) {
              response.data.forEach(item => {
                const fields = {
                  id: item.id,
                  bankName: item.bankName || "",
                  bankCode: item.bankCode || "",
                  payin: item.payin || false,
                  payout: item.payout || false,
                  providerBankName: item.bankName || "",
                  pspId: item.pspId,
                  whiteLabelIds: item.whiteLabelIds,
                  currency: item.currency
                };
                bankCodesList.push(new Form(fields, this.state.bankCodeFormRules));
              });
            } else {
              bankCodesList.push(this.getDefaultBankCodeForm());
            }
            if (response.banks.length) {
              const transformed = response.banks.map((item) => ({ label: item.name, value: item.name }));
              transformed.sort((elemA, elemB) => elemA.value.localeCompare(elemB.value));
              
              this.setState({
                banks: transformed,
                systemBankCodes: response.banks
              });
            }
            this.setState({
              bankCodeForms: bankCodesList,
              isLoadingBankCodes: false
            });
          }
        })
        .catch(err => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message,
              isLoadingBankCodes: false
            });
          }
        });
    }
  };

  getSytemBankCode = (bankName) => {
    const { systemBankCodes } = this.state;
    const bank = systemBankCodes.find(item => item.name === bankName);
    return (bank && bank.code) || "";
  };

  getDefaultBankCodeForm = () => {
    const { bankCodeFormRules, controls } = this.state;

    return new Form({
      id: "",
      bankName: "",
      bankCode: "",
      payin: false,
      payout: false,
      providerBankName: "",
      whiteLabelIds: controls.whiteLabelIds,
      pspId: controls.pspId,
      currency: controls.currency
    }, bankCodeFormRules);
  };

  onAddBankCodeItem = (item) => {
    const { bankCodeForms } = this.state;
    bankCodeForms.push(item || this.getDefaultBankCodeForm());
    this.setState({
      bankCodeForms
    });
  };

  onDeleteBankCodeItem = (index) => {
    const { bankCodeForms } = this.state;

    if (bankCodeForms[index].id) {
      crudActions.remove(`v1/banks/${bankCodeForms[index].id}`)
        .then(() => {
          bankCodeForms.splice(index, 1);
          this.setState({
            bankCodeForms
          });
        })
        .catch(() => {
          this.setState({
            showAlert: true,
            alertType: "error",
            alertMessage: "Please, try again."
          });
        });
    } else {
      bankCodeForms.splice(index, 1);
      this.setState({
        bankCodeForms
      });
    }
  };

  onBankCodeItemChange = (event, index, field, isCheckbox) => {
    const { bankCodeForms } = this.state;

    if (isCheckbox) {
      bankCodeForms[index][field] = !bankCodeForms[index][field];
    } else if (event.value) {
      bankCodeForms[index][field] = event.value;
    } else {
      bankCodeForms[index][field] = event.target.value;
    }

    this.setState({
      bankCodeForms
    });
  };

  isBankCodePresent = (item) => {
    const { bankCodeForms } = this.state;
    const bankCodesData = [];
    if (bankCodeForms) {
      bankCodeForms.forEach(form => {
        bankCodesData.push(form.data());
      });
    }
    const isBankCodeExists = bankCodesData.find(elem => elem.bankCode &&
      ((elem.bankCode.toUpperCase() === String(item.bankCode).toUpperCase()) ||
      (elem.bankCode.toUpperCase().includes(String(item.bankCode).toUpperCase())) ||
      (String(item.bankCode).toUpperCase().includes(elem.bankCode.toUpperCase())))
    );
    
    return isBankCodeExists;
  };

  mapBankName = (item) => {
    const { banks } = this.state;
    const existingBank = banks.find(elem =>
      (elem.value.toUpperCase() === String(item.bankName).toUpperCase()) ||
      (elem.value.toUpperCase().includes(String(item.bankName).toUpperCase())) ||
      (String(item.bankName).toUpperCase().includes(elem.value.toUpperCase()))
    );
    return (existingBank && existingBank.value) || "";
  };

  onFileChange = (e) => {
    const { bankCodeFormRules, controls } = this.state;

    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    reader.onload = (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: "binary", header: 1 });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);
      if (parsedData.length) {
        parsedData.forEach(item => {
          if (!this.isBankCodePresent(item)) {
            const newItem = new Form({
              id: "",
              bankName: this.mapBankName(item) || item.bankName,
              bankCode: item.bankCode,
              providerBankName: item.bankName,
              payin: false,
              payout: false,
              whiteLabelIds: controls.whiteLabelIds,
              pspId: controls.pspId,
              currency: controls.currency
            }, bankCodeFormRules);

            this.onAddBankCodeItem(newItem);
          }
        });
      }
    };
  };

  onSaveBankCodes = () => {
    const { bankCodeForms, controls } = this.state;
    let isFormsValid = true;

    bankCodeForms.forEach(form => {
      form.isFormValid();
      if (!form.isFormValid()) {
        isFormsValid = false;
      }
    });

    this.setState({
      bankCodeForms
    });

    if (isFormsValid) {
      this.setState({
        isLoadingBankCodes: true
      });

      const data = [];
      bankCodeForms.forEach(form => {
        const formData = form.data();
        if (!formData.id) {
          delete formData.id;
        }
        data.push(formData);
      });

      crudActions.post(`v1/banks`, data)  
        .then(response => {
          this.setState({
            showAlert: true,
            alertType: "success",
            alertMessage: response.message || "Operation successful.",
            isLoadingBankCodes: false,
            bankCodeForms: [],
            isSecondPanelVisible: false,
            controls: Object.assign(controls, {
              pspId: "",
              currency: ""
            })
          });
        })
        .catch(
          (err) => {
            if (err && err.message) {
              this.setState({
                showAlert: true,
                alertType: "error",
                alertMessage: err.message,
                isLoadingBankCodes: false
              });
            }
          }
        );
    }
  };

  onConfirm = () => {
    this.setState({
      showAlert: false,
      alertType: "success",
      alertMessage: ""
    });
  };

  render() {
    const {
      bankCodeForms,
      companies,
      controls,
      currencies,
      isLoading,
      isLoadingBankCodes,
      isSecondPanelVisible,
      roleId,
      showAlert,
      alertType,
      alertMessage
    } = this.state;

    const loadingContainer = (
      <div
        style={ {
          width: "100%",
          height: "400px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        } }
      >
        <Spinner smallContainer={ true } />
      </div>
    );

    return (
      <Row flexGrow={ 1 } className="module generateReports editClient bankCodes" vertical='start'>
        <Column flexGrow={ 1 }>
          <Row className="header" flexGrow={ 1 } horizontal='space-between' vertical='center'>
            <Column>
              <Row horizontal='center' vertical='center' style={ { paddingLeft: 15 } }>
                <img src={ MerchantsIcon } alt="" style={ { marginRight: 10 } }/>
                Bank Codes
              </Row>
            </Column>
            <Column horizontal='end'>
              <Row horizontal='end' vertical='center'>
                {this.checkPageAccess("BANKLIST_EDIT") && (
                  <Column horizontal='end' style={ {paddingRight: 15} }>
                    <Row horizontal='end' vertical='center' style={ {color: '#ccc', fontSize: '12px'} }>
                      <NavLink to={ `/add-bank-code` } className="btn add-button">
                        ADD SYSTEM BANK CODE
                      </NavLink>
                    </Row>
                  </Column>
                )}
              </Row>
            </Column>
          </Row>
          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
            <Column flexGrow={ 1 } vertical='start' className="panel-block">
              <Panel>
                <Panel.Heading>
                  <Panel.Title>
                    SELECT
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  { isLoading ? (
                    <>{ loadingContainer }</>
                  ) : (
                    <div className="panel-content" style={ {overflow: 'unset'} }>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> White Label </label>
                          <Multiselect
                            id="whiteLabelIds"
                            name="whiteLabelIds"
                            selectedItems={ this.mapSelectedItems("whiteLabelIds", "companies") }
                            items={ companies }
                            required={ true }
                            disabled={ roleId !== "MASTER_TECH" && roleId !== "MASTER_ADMIN" }
                            type={ "whiteLabelIds" }
                            onChange={ (value) => this.onValueChange(value, 'whiteLabelIds') }
                          />
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Provider </label>
                          <Select
                            id="pspId"
                            name="pspId"
                            value={ controls.pspId || "" }
                            className={
                              controls.errors.has("pspId")
                                ? "error-field"
                                : ""
                            }
                            required={ true }
                            clearable={ false }
                            disabled={ !controls.whiteLabelIds }
                            onChange={ (value) =>
                              this.onValueChange(value, "pspId")
                            }
                            options={
                              controls.whiteLabelIds
                                ? this.getWhiteLabelsProviders()
                                : []
                            }
                          />
                        </Column><Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Currency </label>
                          <Select
                            className={ controls.errors.has('currency') ? 'error-field' : "" }
                            value={ controls.currency || '' }
                            required={ true }
                            disabled={ !controls.pspId }
                            clearable={ false }
                            onChange={ (value) => this.onValueChange(value, 'currency') }
                            options={ currencies }
                          />
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <span/>
                        </Column>
                      </Row>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <span/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <span/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <span/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label/>
                          <CustomButton
                            title="Filter"
                            type="button"
                            onClick={ (e) => this.onFilterBankCodes() }
                          />
                        </Column>
                      </Row>
                    </div>
                  )}
                </Panel.Body>
              </Panel>

              {isSecondPanelVisible && (
                <Panel>
                  <Panel.Heading>
                    <Panel.Title>DATA ENTRIES</Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    {isLoadingBankCodes && (
                      <>{ loadingContainer }</>
                    )}
                    <div className={ `panel-content provider-accounts ${isLoadingBankCodes && 'hidden'}` }>
                      { bankCodeForms.length > 0 && bankCodeForms.map((bankCodeForm, index) => 
                        <Row
                          key={ index }
                          flexGrow={ 1 }
                          horizontal='start'
                          wrap
                          vertical='end'
                          style={ { width: '100%' } }
                          className="account-row"
                        >
                          
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <label> Bank </label>
                            <Select
                              className={ bankCodeForm.errors.has('bankName') ? 'error-field' : "" }
                              value={ bankCodeForm.bankName || '' }
                              required={ true }
                              clearable={ false }
                              onChange={ (event) => this.onBankCodeItemChange(event, index, 'bankName') }
                              options={ this.filterBanks(bankCodeForm.bankName) }
                              disabled={ !this.checkPageAccess("BANKLIST_EDIT") }
                            />
                          </Column>

                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <label> System Bank Code </label>
                            <input
                              className={ "form-control " + (bankCodeForm.errors.has('bankCode') ? 'error-field' : "") }
                              value={ this.getSytemBankCode(bankCodeForm.bankName) }
                              disabled={ true }
                              onChange={ (event) => this.onBankCodeItemChange(event, index, 'systemBankCode') }
                            />
                          </Column>

                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <label> Provider Bank Name </label>
                            <input
                              className={ "form-control " + (bankCodeForm.errors.has('providerBankName') ? 'error-field' : "") }
                              value={ bankCodeForm.providerBankName || '' }
                              disabled={ true }
                              onChange={ (event) => this.onBankCodeItemChange(event, index, 'providerBankName') }
                            />
                          </Column>
                          
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <label> Provider Bank Code </label>
                            <input
                              className={ "form-control " + (bankCodeForm.errors.has('bankCode') ? 'error-field' : "") }
                              value={ bankCodeForm.bankCode || '' }
                              placeholder="Type here..."
                              disabled={ !this.checkPageAccess("BANKLIST_EDIT") }
                              onChange={ (event) => this.onBankCodeItemChange(event, index, 'bankCode') }
                            />
                          </Column>
                          
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column checkbox-column">
                            <label> Payin </label>
                            <Row className="styled-input--square" wrap horizontal='start' vertical='end' style={ { width: '100%' } }>
                              <div className="styled-input-single">
                                <input
                                  id={ `payin-${index}` }
                                  type="checkbox"
                                  checked={ bankCodeForm.payin }
                                  value={ bankCodeForm.payin }
                                  disabled={ !this.checkPageAccess("BANKLIST_EDIT") }
                                  onChange={ (event) => this.onBankCodeItemChange(event, index, 'payin', true) }
                                />
                                <label
                                  style={ { fontWeight: "normal" } }
                                  htmlFor={ `payin-${index}` }
                                  disabled={ !this.checkPageAccess("BANKLIST_EDIT") }
                                />
                              </div>
                            </Row>
                          </Column>
                          
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column checkbox-column">
                            <label> Payout </label>
                            <Row className="styled-input--square" wrap horizontal='start' vertical='end' style={ { width: '100%' } }>
                              <div className="styled-input-single">
                                <input
                                  id={ `payout-${index}` }
                                  type="checkbox"
                                  checked={ bankCodeForm.payout }
                                  value={ bankCodeForm.payout }
                                  disabled={ !this.checkPageAccess("BANKLIST_EDIT") }
                                  onChange={ (event) => this.onBankCodeItemChange(event, index, 'payout', true) }
                                />
                                <label
                                  style={ { fontWeight: "normal" } }
                                  htmlFor={ `payout-${index}` }
                                  disabled={ !this.checkPageAccess("BANKLIST_EDIT") }
                                />
                              </div>
                            </Row>
                          </Column>

                          {this.checkPageAccess("BANKLIST_EDIT") && (
                            <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                              <Row horizontal='start' wrap vertical='end' style={ { width: '100%' } }>
                                <Column vertical='center' className="actions">
                                  <button
                                    className="delete"
                                    onClick={ () => this.onDeleteBankCodeItem(index) }
                                  >
                                    X
                                  </button>
                                    
                                  {index === bankCodeForms.length - 1 && (
                                    <button
                                      className="add"
                                      onClick={ () => this.onAddBankCodeItem() }
                                    >
                                      <span className="plus"> + </span>
                                    </button>
                                  )}
                                </Column>
                              </Row>
                            </Column>
                          )}
                          
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <span/>
                          </Column>
                          
                        </Row>
                      )}
                      
                      {bankCodeForms.length === 0 && this.checkPageAccess("BANKLIST_EDIT") && (
                        <Row flexGrow={ 1 } horizontal='start' wrap vertical='end' style={ { width: '100%' } }>
                          <Column flexGrow={ 1 } horizontal='start' alignSelf='start' className="input-column" style={ { flexDirection: 'row', alignItems: 'center' } }>
                            Add Bank Code
                            <button
                              className="add"
                              onClick={ () => this.onAddBankCodeItem() }
                            >
                              <span className="plus"> + </span>
                            </button>
                          </Column>
                        </Row>
                      )}

                      <Row flexGrow={ 1 } horizontal='start' wrap vertical='end' style={ { width: '100%' } }>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label htmlFor="file-upload" className="file-upload">
                            <input
                              id="file-upload"
                              type="file"
                              accept=".xlsx, .xls"
                              multiple={ false }
                              onChange={ (e) => this.onFileChange(e) }
                            />
                            <span className="upload"> Add from file </span>
                          </label>
                          
                        </Column>
                        <Column flexGrow={ 1 } vertical='end' alignSelf='end' className="input-column">
                          <span/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='end' alignSelf='end' className="input-column">
                          <span/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' className="input-column">
                          <CustomButton
                            title="Save"
                            type="submit"
                            disabled={ !bankCodeForms.length || !this.checkPageAccess("BANKLIST_EDIT") }
                            onClick={ () => this.onSaveBankCodes() }
                          />
                        </Column>
                      </Row>
                    </div>
                  </Panel.Body>
                </Panel>
              )}

            </Column>
          </Row>
        </Column>

        {showAlert && (
          <Alert
            show={ showAlert }
            title={ alertType }
            type={ alertType }
            text={ alertMessage }
            confirmButtonColor="#187EED"
            onConfirm={ this.onConfirm }
          />
        )}

      </Row>
    );
  }
}

export default BankCodes;
