import React, { Component } from 'react';
import { Column, Row } from 'simple-flexbox';
import { Panel } from "react-bootstrap";
import { saveAs } from 'file-saver';

import Alert from "@sm/components/custom/Alert";
import CustomButton from "@sm/components/custom/Button";
import Form from "@sm/core/Form";
import Multiselect from "@sm/components/custom/customMultiselect";
import Select from "@sm/components/custom/Select";
import Spinner from '@sm/components/Spinner';
import Table from '@sm/components/custom/Table';

import '@assets/css/editClient.css';
import '@assets/css/bankCodes.css';
import MerchantsIcon from '@assets/images/merchants.png';

import { FETCH_PERMISSIONS } from '@sm/actions/types';
import { crudActions } from "@sm/services/crudActions";

const store = require('@sm/reducers/index');

class BankCodes extends Component {
  state = {
    access: [],
    roleId: "",

    controls: new Form({
      whiteLabelIds: [],
      currency: ""
    }, [{
      name: "whiteLabelIds",
      type: "isArray",
      rules: {
        required: true
      }
    }, {
      name: "currency",
      type: "isString",
      rules: {
        required: true
      }
    }]),

    companies: [],
    bankCodes: [],
    currencies: [],
    savedData: {},

    isLoading: true,
    isLoadingBankCodes: false,
    isSecondPanelVisible: false,
    showAlert: false,
    alertType: "success",
    alertMessage: ""
  };

  componentDidMount() {
    const storeState = store.default.getState().authReducer;
    if (storeState.access) {
      const roleId = storeState.roleId;
      this.setState({
        access: storeState.access,
        roleId
      });
    }

    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().authReducer;

      if (state.userUpdate === FETCH_PERMISSIONS) {
        this.setState({
          access: state.access
        });
      }
    });

    Promise.all([
      crudActions.get(`v1/companies/clients`),
      crudActions.get(`v1/adminpanel/lookups`)
    ]).then((response) => {
      const clients = response[0];
      const lookups = response[1];
      if (clients) {
        const companiesList = clients.map(elem => elem.company);
        companiesList.sort((elemA, elemB) => elemA.label.localeCompare(elemB.label));
        this.setState({
          companies: companiesList,
          isLoading: false
        }, () => {
          const { companies } = this.state;
          if (companies.length) {
            this.onValueChange(companies, 'whiteLabelIds');
          }
        });
      }
      if (lookups) {
        const lookupsSorted = lookups.currency.sort((elemA, elemB) => elemA.value.localeCompare(elemB.value));
        this.setState({
          currencies: lookupsSorted,
          isLoading: false
        });
      }
    });
  };

  loadCompaniesData = (whiteLabelIds) => {
    if (whiteLabelIds.length) {
      Promise.all(
        whiteLabelIds.map(wlId => crudActions.get(`v1/adminpanel/company/${wlId}`))
      ).then((data) => {
        if (data) {
          let currencies = [];
          for (let i = 0; i < data.length; i++) {
            currencies = currencies.concat(data[i].storedLookups.currency || []);
          }
          if (currencies.length) {
            currencies.sort((elemA, elemB) => elemA.localeCompare(elemB));
            const unique = [...new Set(currencies)];
            const transformed = unique.map((item) => ({ label: item, value: item }));
            
            this.setState({
              currencies: transformed
            });
          }
          
        }
      });
    } else {
      this.setState({
        currencies: [],
        controls: Object.assign(this.state.controls, {
          currency: ""
        })
      });
    }
  };

  onValueChange = (event, field) => {
    const { controls } = this.state;
    if (!event) {
      controls[field] = event;
    } else if (event.target) {
      controls[field] = event.target.value;
    } else if (event.value) {
      controls[field] = event.value;
    } else {
      controls[field] = event.map(elem => elem.value);
    }

    if (field === "whiteLabelIds") {
      this.loadCompaniesData(controls[field]);
    }

    this.setState({
      controls
    });
  };

  mapSelectedItems = (type, lookups) => {
    const field = this.state.controls[type];
    const lookup = this.state[lookups];
    return field.map(elem => {
      return lookup.find(lookupElem => elem === lookupElem.value);
    });
  };

  checkPageAccess = (permissionName) => {
    const { access } = this.state;
    const foundPermission = access.find(elem => elem.permission === permissionName);
    if (!foundPermission) {
      return false;
    }

    return foundPermission.state;
  };

  onFilterBankCodes = () => {
    const { controls } = this.state;
    this.setState({
      isLoadingBankCodes: true,
      isSecondPanelVisible: true,
      bankCodes: []
    });

    crudActions.post(`v1/banks/merchant/search?currency=${ controls.currency }`)
      .then(response => {
        if (response.length) {
          this.setState({
            bankCodes: response,
            isLoadingBankCodes: false,
            savedData: {
              currency: controls.currency
            }
          });
        } else {
          this.setState({
            showAlert: true,
            alertType: "info",
            alertMessage: "There is no bank codes for the selected currency.",
            isLoadingBankCodes: false,
            isSecondPanelVisible: false
          });
        }
      })
      .catch(err => {
        if (err && err.message) {
          this.setState({
            showAlert: true,
            alertType: "error",
            alertMessage: err.message,
            isLoadingBankCodes: false
          });
        }
      });
  };

  onExportOpen = () => {
    this.setState({
      isExportSettingsOpen: !this.state.isExportSettingsOpen
    });
  };

  onExportClick = () => {
    const { savedData } = this.state;

    this.setState({
      isExportSettingsOpen: false
    });

    crudActions.post(`v1/banks/merchant/search/xlsx?currency=${ savedData.currency }`).then(
      response => {
        if (response) {
          response.blob().then(
            result => {
              saveAs(result, `Bank Codes - ${ savedData.currency }.xlsx`);
            }
          );
        }
      }
    ).catch(
      err => {
        this.setState({
          showAlert: true,
          alertType: "error",
          alertMessage: err.message
        });
      }
    );
  };

  getColumns = () => {
    return [{
      value: "code",
      label: "Bank Code",
      leftAligned: true
    }, {
      value: "name",
      label: "Bank",
      leftAligned: true
    }];
  };

  onConfirm = () => {
    this.setState({
      showAlert: false,
      alertType: "success",
      alertMessage: ""
    });
  };

  render() {
    const {
      bankCodes,
      companies,
      controls,
      currencies,
      isExportSettingsOpen,
      isLoading,
      isLoadingBankCodes,
      isSecondPanelVisible,
      showAlert,
      alertType,
      alertMessage,
      roleId
    } = this.state;

    const loadingContainer = (
      <div
        style={ {
          width: "100%",
          height: "400px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        } }
      >
        <Spinner smallContainer={ true } />
      </div>
    );

    return (
      <Row flexGrow={ 1 } className={ `module generateReports editClient bankCodes` } vertical='start'>
        <Column flexGrow={ 1 }>
          <Row className="header" flexGrow={ 1 } horizontal='space-between' vertical='center'>
            <Column>
              <Row horizontal='center' vertical='center' style={ { paddingLeft: 15 } }>
                <img src={ MerchantsIcon } alt="" style={ { marginRight: 10 } }/>
                Bank Codes
              </Row>
            </Column>
          </Row>
          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
            <Column flexGrow={ 1 } vertical='start' className="panel-block">
              <Panel>
                <Panel.Heading>
                  <Panel.Title>
                    SELECT
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  { isLoading ? (
                    <>{ loadingContainer }</>
                  ) : (
                    <div className="panel-content" style={ { overflow: 'unset' } }>
                      <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='end'>
                        <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' alignSelf='start'>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <label> White Label </label>
                            <Multiselect
                              id="whiteLabelIds"
                              name="whiteLabelIds"
                              selectedItems={ this.mapSelectedItems("whiteLabelIds", "companies") }
                              items={ companies }
                              required={ true }
                              disabled={ roleId !== "MASTER_TECH" && roleId !== "MASTER_ADMIN" }
                              type={ "whiteLabelIds" }
                              onChange={ (value) => this.onValueChange(value, 'whiteLabelIds') }
                            />
                          </Column>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <label> Currency </label>
                            <Select
                              className={ controls.errors.has('currency') ? 'error-field' : "" }
                              value={ controls.currency }
                              required={ true }
                              clearable={ false }
                              onChange={ (value) => this.onValueChange(value, 'currency') }
                              options={ currencies }
                            />
                          </Column>
                          <Column flexGrow={ 1 } className="input-column empty-column">
                            <span/>
                          </Column>
                          <Column flexGrow={ 1 }   vertical='end' alignSelf='end' className="input-column" style>
                            <CustomButton
                              title="SUBMIT"
                              type="submit"
                              onClick={ () => this.onFilterBankCodes() }
                            />
                          </Column>
                        </Row>
                      </Row>
                    </div>
                  )}
                </Panel.Body>
              </Panel>
              {isSecondPanelVisible && (
                <Panel>
                  <Panel.Heading>
                    <Panel.Title>DATA ENTRIES</Panel.Title>
                    <div className="settings-panel">
                      <span className="setting" style={ { float: "right", paddingRight: "10px" } } onClick={ () => this.onExportOpen() }>
                        <svg className="setting-icon" viewBox="0 0 32 32">
                          <g>
                            <path clipRule="evenodd" d="M8.624,21.336h2.015c1.402-7.953,8.329-14,16.684-14   c0.35,0,0.683,0.003,1.019,0.006l-3.664,3.663c-0.391,0.391-0.391,1.023,0,1.414c0.195,0.195,0.451,0.293,0.707,0.293   c0.256,0,0.512-0.098,0.707-0.293L32,6.356l-5.907-6.063c-0.391-0.391-1.023-0.391-1.414,0c-0.391,0.391-0.391,1.023,0,1.414   l3.631,3.631c-0.318-0.001-0.62-0.003-0.945-0.003C17.895,5.336,10.066,12.271,8.624,21.336z" fillRule="evenodd"/>
                            <path clipRule="evenodd" d="M29,15c-0.552,0-1,0.448-1,1v14H2V10h9c0.552,0,1-0.448,1-1   c0-0.552-0.448-1-1-1h-0.03H2c-1.104,0-2,0.896-2,2v20c0,1.104,0.896,2,2,2h26c1.104,0,2-0.896,2-2V16C30,15.448,29.552,15,29,15z" fillRule="evenodd"/>
                          </g>
                        </svg>
                        <span className="text"> Export </span>
                      </span>
                    </div>
                    { isExportSettingsOpen && (
                      <div ref={ node => this.exportNode = node } className="export-settings">
                        <p className="export-setting-item" onClick={ () => this.onExportClick() }> Export to Excel </p>
                      </div>
                    )}
                  </Panel.Heading>
                  <Panel.Body>
                    {isLoadingBankCodes ? (
                      <>{ loadingContainer }</>
                    ) : (
                      <div className="panel-content provider-accounts">
                        <Table
                          columns={ this.getColumns() }
                          data={ bankCodes }
                          headerColor={ "#223679" }
                          headerTextColor={ "#FFFFFF" }
                          isStriped={ true }/>
                      </div>
                    )}
                  </Panel.Body>
                </Panel>
              )}
            </Column>
          </Row>
        </Column>

        {showAlert && (
          <Alert
            show={ showAlert }
            title={ alertType }
            type={ alertType }
            text={ alertMessage }
            confirmButtonColor="#187EED"
            onConfirm={ this.onConfirm }
          />
        )}

      </Row>
    );
  }
}

export default BankCodes;
