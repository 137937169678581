import React, { Component } from 'react';
import { Column, Row } from 'simple-flexbox';
import { Panel } from "react-bootstrap";

import Alert from "@sm/components/custom/Alert";
import PageHeader from "@sm/components/PageHeader";
import Select from "@sm/components/custom/Select";
import Spinner from '@sm/components/Spinner';

import '@assets/css/affiliateLinks.css';
import CopyIcon from "@assets/images/copy-icon.png";
import AffiliateLinksIcon from '@assets/images/user-management.png';

import { crudActions } from "@sm/services/crudActions";

class AffiliateLinks extends Component {
  state = {
    isLoading: true,
    products: [{
      value: "agnt",
      label: "Agent"
    }, {
      value: "mcnt",
      label: "Merchant"
    }, {
      value: "wl",
      label: "White Label"
    }],

    agents: [],
    agentId: "",

    product: "",
    links: {
      agnt: "",
      mcnt: "",
      wl: ""
    }
  };

  componentDidMount() {
    crudActions.get('v1/agent/all').then(
      (agents) => {
        if (agents) {
          this.setState({
            agents,
            isLoading: false
          });
        }
      }
    );
  };

  loadAgentLinks = () => {
    const { agentId } = this.state;
    crudActions.get(`v1/agent/links/${agentId}`).then(
      (links) => {
        if (links) {
          this.setState({
            links: {
              agnt: links.agentRL,
              mcnt: links.merchantRL,
              wl: links.whiteLabelRL
            }
          });
        }
      }
    ).catch(
      (err) => {
        if (err && err.message) {
          this.setState({
            showAlert: true,
            alertType: "error",
            alertMessage: err.message
          });
        }
      }
    );
  };

  onProductChange = (newProduct) => {
    this.setState({
      product: newProduct.value
    });
  };

  onAgentChange = (newAgent) => {
    this.setState({
      agentId: newAgent.value
    }, this.loadAgentLinks);
  };

  fallbackCopyTextToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      const msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg); // eslint-disable-line no-console
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err); // eslint-disable-line no-console
    }

    document.body.removeChild(textArea);
  };

  copyTextToClipboard = () => {
    const { product, links } = this.state;
    const copiedLink = links[product];

    if (!navigator.clipboard) {
      this.fallbackCopyTextToClipboard(copiedLink);
      return;
    }
    navigator.clipboard.writeText(copiedLink).then(function() {
      console.log('Async: Copying to clipboard was successful!'); // eslint-disable-line no-console
    }, function(err) {
      console.error('Async: Could not copy text: ', err); // eslint-disable-line no-console
    });
  };

  onConfirm = () => {
    this.setState({
      showAlert: false,
      alertType: "success",
      alertMessage: ""
    });
  };

  render() {
    const { product, products, agentId, agents, links, isLoading, showAlert, alertType, alertMessage } = this.state;
    return (
      <Row flexGrow={ 1 } className="module affiliate-links" vertical='start'>
        <Column flexGrow={ 1 }>
          <PageHeader
            title="Referral links"
            img={ AffiliateLinksIcon }
          />
          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
            <Column flexGrow={ 1 } vertical='start' className="panel-block">
              <Panel>
                <Panel.Heading>
                  <Panel.Title>
                    REFERRAL LINKS
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  { isLoading ? <div style={ { width: "100%", height: "200px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                    <Spinner smallContainer={ true } />
                  </div> : <div className="panel-content" style={ {overflow: 'unset'} }>
                    <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                      <Column vertical='start' alignSelf='start' className="input-column">
                        <label> Agent </label>
                        <Select id="agentId"
                          name="agentId"
                          value={ agentId || '' }
                          required={ true }
                          clearable={ false }
                          onChange={ (value) => this.onAgentChange(value) }
                          options={ agents }
                        />
                      </Column>
                      <Column vertical='start' alignSelf='start' className="input-column">
                        <label> Product </label>
                        <Select id="product"
                          name="product"
                          value={ product || '' }
                          required={ true }
                          clearable={ false }
                          disabled={ !agentId }
                          onChange={ (value) => this.onProductChange(value) }
                          options={ products }
                        />
                      </Column>
                      <Column vertical='start' alignSelf='start' className="double-input-column">
                        <label> Signup Link </label>
                        <input
                          className="form-control link-input"
                          value={ product ? links[product] : '' }
                          disabled/>
                        <img
                          data-tip="Copy link"
                          className="copy-icon"
                          src={ CopyIcon }
                          onClick={ this.copyTextToClipboard }
                          alt="copy"/>
                      </Column>
                    </Row>
                  </div> }
                </Panel.Body>
              </Panel>
            </Column>
          </Row>
        </Column>

        {showAlert && (
          <Alert
            show={ showAlert }
            title={ alertType }
            type={ alertType }
            text={ alertMessage }
            confirmButtonColor="#187EED"
            onConfirm={ this.onConfirm }
          />
        )}

      </Row>
    );
  }
}

export default AffiliateLinks;
