import React, { Component } from 'react';
import { Panel } from "react-bootstrap";

import Spinner from '@sm/components/Spinner';
import Table from "@sm/components/custom/Table";

import '@assets/css/apiData.css';

import { toFormattedNumber } from "@sm/utils/utils";
import { DASHBOARD_PAYOUT_EVENT } from '@sm/actions/types';
const store =  require('@sm/reducers/index');

class DashBoardPayoutComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      payout: undefined,
      loading: true,
      socket: false,

      columnsFirst: [{
        value: "attempts",
        label: "Transactions",
        leftAligned: true,
        isNumber: true
      }, {
        value: "pending",
        label: "Pending",
        rightAligned: true,
        isNumber: true
      }, {
        value: "rejected",
        label: "Rejected",
        rightAligned: true,
        isNumber: true
      }, {
        value: "failed",
        label: "Failed",
        rightAligned: true,
        isNumber: true
      }, {
        value: "successful",
        label: "Successful",
        rightAligned: true,
        isNumber: true
      }],

      payoutColumns: [{
        value: "currency",
        label: "Currency",
        leftAligned: true,
        isNumber: false
      }, {
        value: "attempts",
        label: "Total",
        leftAligned: true,
        isNumber: true
      }, {
        value: "pending",
        label: "Pending",
        rightAligned: true,
        isNumber: true
      }, {
        value: "rejected",
        label: "Rejected",
        rightAligned: true,
        isNumber: true
      }, {
        value: "failed",
        label: "Failed",
        rightAligned: true,
        isNumber: true
      }, {
        value: "successful",
        label: "Successful",
        leftAligned: true,
        isNumber: true
      }]
    };
  }

  subscribeFunction = null;

  componentDidMount(){
    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().dashboardReducer;

      if (state.update === "LOADING") {
        this.setState({
          loading: true
        });
      }

      if (state.update === DASHBOARD_PAYOUT_EVENT && this.props.thisMonth && state.payout.socket) {
        this.setState({ payout: state.payout, loading: false, socket: state.payout.socket });
        return;
      }

      if (state.update === DASHBOARD_PAYOUT_EVENT && !state.payout.socket) {
        this.setState({ payout: state.payout, loading: false, socket: state.payout.socket });
      }
    });
  };

  componentWillUnmount() {
    if (this.subscribeFunction) {
      this.subscribeFunction();
    }
  };

  firstTableData = () => {
    const { payout } = this.state;

    if (!payout) {
      return [];
    }

    return [{
      attempts: payout.attempts,
      pending: payout.pending,
      failed: payout.failed,
      rejected: payout.rejected,
      successful: payout.successful
    }];
  };

  secondTableData = () => {
    const { payout } = this.state;

    if (!payout || !payout.payouts) {
      return [];
    }
    const firstColumn = this.state.payoutColumns[0].value;
    return payout.payouts.sort((elem1, elem2) => elem1[firstColumn].localeCompare(elem2[firstColumn]))
      .map(elem => {
        return {
          successful: toFormattedNumber(elem.successful),
          pending: toFormattedNumber(elem.pending),
          failed: toFormattedNumber(elem.failed),
          attempts: toFormattedNumber(elem.attempts),
          rejected: toFormattedNumber(elem.rejected),
          currency: elem.currency
        };
      });
  };

  render() {
    const { payout, loading, columnsFirst, payoutColumns } = this.state;

    let sellsWrap = (
      <Panel>
        <Panel.Heading>
          <Panel.Title> PAYOUTS </Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <Spinner smallContainer={ true } />
        </Panel.Body>
      </Panel>
    );

    if (!loading && payout) {
      sellsWrap = (
        <Panel style={ { height: '100%' } }>
          <Panel.Heading>
            <Panel.Title> PAYOUTS </Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <div className="panel-content table-content payout-requests">
              <Table
                columns={ columnsFirst }
                headerColor={ "#DFDFDF" }
                data={ this.firstTableData() }
                isStriped={ false }
              />
              <Table
                columns={ payoutColumns }
                headerColor={ "#DFDFDF" }
                data={ this.secondTableData() }
                isStriped={ false }
              />
            </div>
          </Panel.Body>
        </Panel>
      );
    }
    return sellsWrap;
  }

}

export default DashBoardPayoutComponent;