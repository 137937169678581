import AgentBackofficeImage from "@assets/images/signup-agent/Backoffice.png";
import AgentSchemeImage from "@assets/images/signup-agent/scheme.png";
import AgentLogo from "@assets/images/signup-agent/logo.png";
import AgentBackgroundModal from "@assets/images/signup-agent/background-modal.png";

import MerchantBackofficeImage from "@assets/images/signup-merchant/Backoffice.png";
import MerchantSchemeImage from "@assets/images/signup-merchant/scheme.png";
import MerchantLogo from "@assets/images/signup-merchant/logo.png";
import MerchantBackgroundModal from "@assets/images/signup-merchant/background-modal.png";
import MerchantApp from "@assets/images/signup-merchant/app.png";

import WhiteLabelBackofficeImage from "@assets/images/signup-whitelabel/Backoffice.png";
import WhiteLabelSchemeImage from "@assets/images/signup-whitelabel/scheme.png";
import WhiteLabelLogo from "@assets/images/signup-whitelabel/logo.png";
import WhiteLabelBackgroundModal from "@assets/images/signup-whitelabel/background-modal.png";
import WhiteLabelApp from "@assets/images/signup-whitelabel/app.png";

import PspBackofficeImage from "@assets/images/signup-psp/Backoffice.png";
import PspSchemeImage from "@assets/images/signup-psp/scheme.mp4";
import PspLogo from "@assets/images/signup-psp/logo.png";
import PspBackgroundModal from "@assets/images/signup-psp/background-modal.png";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  agent: {
    backoffice: AgentBackofficeImage,
    scheme: AgentSchemeImage,
    logo: AgentLogo,
    backgroundModal: AgentBackgroundModal
  },

  merchant: {
    backoffice: MerchantBackofficeImage,
    scheme: MerchantSchemeImage,
    logo: MerchantLogo,
    backgroundModal: MerchantBackgroundModal,
    app: MerchantApp
  },

  "white-label": {
    backoffice: WhiteLabelBackofficeImage,
    scheme: WhiteLabelSchemeImage,
    logo: WhiteLabelLogo,
    backgroundModal: WhiteLabelBackgroundModal,
    app: WhiteLabelApp
  },

  psp: {
    backoffice: PspBackofficeImage,
    scheme: {
      isVideo: true,
      data: PspSchemeImage
    },
    logo: PspLogo,
    backgroundModal: PspBackgroundModal
  }
};