import React, { Component } from 'react';
import StarMids from '@assets/images/white-logo.png';
import "@assets/css/SignUp.css";

import { Row } from 'simple-flexbox';

class SignUpFooter extends Component {
  state = {};

  componentDidMount() {}

  render() {
    return (
      <Row horizontal='space-around' vertical='center' flexGrow={ 1 } className="signup-footer" style={ { width: "100%" } }>
        <p className="signup-rights"> ALL RIGHTS RESERVED TO STARMIDS </p>
        <img src={ StarMids } alt="starmids"/>
        <Row horizontal='center'>
          <span className="signup-rights"> ABOUT </span>
          <span className="signup-rights"> REGISTER </span>
          <span className="signup-rights"> CONTACT </span>
        </Row>
      </Row>
    );
  }
}

export default SignUpFooter;