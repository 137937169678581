import React from 'react';
import { Button } from "react-bootstrap";

const CustomButton = ( props ) => {
  const { title, type, className, disabled, onClick, style } = props;

  return (
    <Button
      type={ type || "button" }
      style={ style }
      disabled={ disabled }
      className={ `btn defaultBtn ${className}` }
      onClick={ onClick }
    >
      { title }
    </Button>
  );
};

export default CustomButton;
