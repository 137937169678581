import React, { Component } from 'react';
import {  Panel } from "react-bootstrap";

import Spinner from '@sm/components/Spinner';
import Table from "@sm/components/custom/Table";

import '@assets/css/apiData.css';

import { toFormattedNumber } from "@sm/utils/utils";
import { DASHBOARD_PURCHASE_COUNTRIES_EVENT } from '@sm/actions/types';
const store =  require('@sm/reducers/index');

class DashBoardPurchasesComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      purchaseMethods: undefined,
      loading: true,
      socket: false,
      columns: [{
        value: "country",
        label: "Country"
      },{
        value: "total",
        label: "Total",
        isNumber: true,
        rightAligned: true
      }]
    };
  }

  subscribeFunction = null;

  componentDidMount(){
    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().dashboardReducer;

      if (state.update === "LOADING") {
        this.setState({
          loading: true
        });
      }

      if (state.update === DASHBOARD_PURCHASE_COUNTRIES_EVENT && this.props.thisMonth && state.purchaseMethods.socket) {
        this.setState({ purchaseMethods: state.purchaseMethods, loading: false, socket: state.purchaseMethods.socket });
        return;
      }

      if (state.update === DASHBOARD_PURCHASE_COUNTRIES_EVENT && !state.purchaseMethods.socket) {
        this.setState({ purchaseMethods: state.purchaseMethods, loading: false, socket: state.purchaseMethods.socket });
      }
    });
  };

  componentWillUnmount() {
    if (this.subscribeFunction) {
      this.subscribeFunction();
    }
  };

  tableData = () => {
    const { purchaseMethods } = this.state;

    if (!purchaseMethods) {
      return [];
    }
    const firstColumn = this.state.columns[0].value;
    return purchaseMethods.sort((elem1, elem2) => elem1[firstColumn] ? elem1[firstColumn].localeCompare(elem2[firstColumn]) : 1)
      .map(elem => {
        return {
          country: elem.country,
          purchases: elem.currency + toFormattedNumber(elem.purchases),
          fxFee: elem.currency + toFormattedNumber(elem.fxFee),
          purchaseFee: elem.currency + toFormattedNumber(elem.purchaseFee),
          total: elem.currency + toFormattedNumber(elem.total)
        };
      });
  };

  render() {
    const { purchaseMethods, loading, columns } = this.state;

    let purchaseMethodsWrap = (
      <Panel>
        <Panel.Heading>
          <Panel.Title> PAYMENTS PER COUNTRY </Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <Spinner smallContainer={ true } />
        </Panel.Body>
      </Panel>
    );

    if (!loading && purchaseMethods) {
      purchaseMethodsWrap = (
        <Panel style={ { height: '100%' } }>
          <Panel.Heading>
            <Panel.Title> PAYMENTS PER COUNTRY </Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <div className="panel-content table-content">
              <Table
                columns={ columns }
                headerColor={ "#DFDFDF" }
                data={ this.tableData() }
                isStriped={ false }
              />
            </div>
          </Panel.Body>
        </Panel>
      );
    }
    return purchaseMethodsWrap;
  }
}

export default DashBoardPurchasesComponent;