import React from 'react';

import '@assets/css/spinner.css';

const spinner = (props) => (
  <div
    className={ `Loader ${props.smallContainer && 'small' } ${props.className}` }
    style={ {color: props.color} }
  >
    Loading...
  </div>
);

export default spinner;