import React, { Component } from 'react';
import { Row } from 'simple-flexbox';

import PageHeader from "@sm/components/PageHeader";

import '@assets/css/apiData.css';

class EmptyScreen extends Component {
  state = {};

  componentDidMount() {
    this.props.history.push(`/dashboard`);
  };

  render() {
    return (
      <Row flexGrow={ 1 } className='module apidata' vertical='start'>
        <PageHeader />
      </Row>
    );
  }
}

export default EmptyScreen;
