import { SHOW_MESSAGE } from '@sm/actions/types';

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = {}, action) {
  switch(action.type) {
  case SHOW_MESSAGE:
    return (action.payload);
  default:
    return state;
  }
}