import React, {Component} from 'react';

import { Row } from 'simple-flexbox';

import '@assets/css/apiData.css';

class EmptySignupScreen extends Component {
  state = {};

  getCookie = (cookieName) => {
    const match = document.cookie.match(new RegExp('(^| )' + cookieName + '=([^;]+)'));
    if (match) return match[2];
  };

  componentDidMount() {
    const search = this.props.location.search.replace("?", "");

    const productMap = {
      agnt: "agent",
      wl: "white-label",
      mcnt: "merchant",
      psp: "psp"
    };

    if (search) {
      const queryParamsArr = search.split("&");
      const queryParams = queryParamsArr.reduce((accumulator, currentElem) => {
        const [key, value] = currentElem.split("=");
        accumulator[key] = value;
        return accumulator;
      }, {});

      if (queryParams.ref && queryParams.p) {
        const date = new Date();
        date.setDate(date.getDate() + 30);
        document.cookie = "agent=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = `agent=${queryParams.ref};expires=${date}`;
        document.cookie = "p=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = `p=${queryParams.p};expires=${date}`;
      }
    }

    const product = this.getCookie("p");

    this.props.history.push(`/sign-up/${productMap[product] || "agent"}`);
  };

  render() {
    return (
      <Row flexGrow={ 1 } className='module apidata' vertical='start'>
        <span/>
      </Row>
    );
  }
}

export default EmptySignupScreen;
