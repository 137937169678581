import React, { Component } from 'react';
import { Row, Column } from 'simple-flexbox';
import { Panel } from "react-bootstrap";

import Alert from "@sm/components/custom/Alert";
import CustomButton from "@sm/components/custom/Button";
import PageHeader from "@sm/components/PageHeader";
import Spinner from '@sm/components/Spinner';

import '@assets/css/sandbox.css';
import UserManagementIcon from '@assets/images/user-management.png';

import { crudActions } from "@sm/services/crudActions";

class UploadBlacklist extends Component {
  state = {
    showAlert: false,
    alertType: "success",
    alertMessage: "",
    isLoading: false
  };

  onConfirm = () => {
    this.setState({
      showAlert: false,
      alertType: "success",
      alertMessage: ""
    });
  };

  onFileChange = (e) => {
    const file = e.target.files.length ? e.target.files[0] : "";
    if (file) {
      const acceptedFormats = ["xlsx", "xls"];
      const splittedName = file.name.split(".");
      const extension = splittedName[splittedName.length - 1];
      if (!acceptedFormats.includes(extension)) {
        this.setState({
          showAlert: true,
          alertType: "error",
          alertMessage: "Wrong file format."
        });
      } else {
        this.setState({
          fileUploaded: e.target.files.length ? e.target.files[0] : ""
        });
      }
    }
  };

  onUpload = () => {
    const { fileUploaded } = this.state;

    if (fileUploaded) {
      this.setState({
        isLoading: true
      });
      const formData = new FormData();
      formData.append("file", fileUploaded);
      crudActions.post(`v1/blacklist/upload`, formData, {}, true).then(
        () => {
          this.setState({
            showAlert: true,
            alertType: "success",
            alertMessage: "Blacklist successfully uploaded.",
            fileUploaded: "",
            isLoading: false
          });
        }
      ).catch(
        err => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message,
              isLoading: false
            });
          }
        }
      );
    }
  };

  render() {
    const {
      alertMessage,
      fileUploaded,
      isLoading,
      showAlert,
      alertType
    } = this.state;

    return (
      <Row flexGrow={ 1 } className="module sandbox blacklist" vertical='start'>
        <Column flexGrow={ 1 }>
          <PageHeader
            title={ 'Upload Blacklist' }
            img={ UserManagementIcon }
          />
          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
            <Column flexGrow={ 1 } vertical='start' className="panel-block">
              <Panel>
                <Panel.Heading>
                  <Panel.Title>
                    UPLOAD BLACKLIST
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  <div className="panel-content" style={ {overflow: 'unset'} }>
                    <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='end'>
                      <Column flexGrow={ 1 } vertical='start' alignSelf="end" className="input-column">
                        <label> Upload XLSX file </label>
                        <input
                          id="file-upload"
                          type="file"
                          accept=".xlsx, .xls"
                          onChange={ (e) => this.onFileChange(e) }
                        />
                        <label htmlFor="file-upload" className="file-upload">
                          { !fileUploaded ? "No files uploaded yet." : fileUploaded.name}
                          <span className="browse"> Browse... </span>
                        </label>
                      </Column>
                      <Column flexGrow={ 1 } vertical='start' alignSelf="end" className="input-column">
                        <CustomButton
                          type="submit"
                          disabled={ isLoading }
                          onClick={ () => this.onUpload() }
                        />
                      </Column>
                    </Row>
                  </div>
                  { isLoading && (
                    <div style={ { width: "100%", height: "400px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                      <Spinner smallContainer={ true } />
                    </div>
                  )}
                </Panel.Body>
              </Panel>
            </Column>
          </Row>
        </Column>

        {showAlert && (
          <Alert
            show={ showAlert }
            title={ alertType }
            type={ alertType }
            text={ alertMessage }
            confirmButtonColor={ alertType === "success" ? "#187EED" : "#DD6B55" }
            onConfirm={ this.onConfirm }
          />
        )}

      </Row>
    );
  }
}

export default UploadBlacklist;
