// eslint-disable-next-line import/no-anonymous-default-export
export default {
  agent: {
    firstSection: [{
      type: "h1",
      text: "Make Agent introductions and earn more!"
    }, {
      type: "h3",
      text: "You can sign up in two minutes and instantly start referring people from the iGaming, Forex and Crypto sectors who would be interested in acting as an agent."
    }, {
      type: "h2",
      text: "Why become a Starmids Partner?"
    }, {
      type: "ul",
      text: [
        "Promote an up and coming Industry leading payment technology brand",
        "Multi-Language and Multi-Country Brand",
        "Simplifying how you can make referrals",
        "Clear Analytics and Reporting",
        "Regular Monthly Earnings"
      ]
    }],
    secondSection: [{
      type: "h1",
      text: "Making sense of high risk payment processing in the iGaming, Forex and Crypto space."
    }, {
      type: "h3",
      text: "StarMids is an industry leading software platform to manage online payments in a way that mitigates risk, reduces overall payment processing fees and that provides a market leading back office system for all stakeholders."
    }, {
      type: "h3",
      text: "We know how important your referrals are and we will go above and beyond to look after all referrals made by you. We develop our payment software and all relationships for the long term."
    }],
    thirdSection: [{
      type: "h2",
      className: "signup-action-header",
      text: "Referring has never been so easy, send a link to your connections and we will do the rest!"
    }],
    fourthSection: [{
      type: "h4",
      text: "The StarMids Partner programme provides a generous revenue share commission structure that has no limits, you earn alongside us as a business making it a true partnership programme. Whether you are a payments advisor, an online affiliate or someone who is involved in the payments industry you are able to generate income via our industry leading agent partner programme."
    }, {
      type: "h4",
      text: "What is the StarMids Agent program?"
    }, {
      type: "ol",
      kind: "1",
      text: [{
        header: "GETTING STARTED",
        paragraph: "If you have a network of payment individuals you can send them a link and refer them to us and we will do the rest."
      }, {
        header: "YOUR LINK",
        paragraph: "A referral clicks your link. We tag this referral with your unique ID and assess their requirements."
      }, {
        header: "REGISTERING REFERRALS",
        paragraph: "The agent you refer signs up and starts referring to us."
      }, {
        header: "PROFIT ACCRUAL",
        paragraph: "You receive up to 10% of our fee according to your Agent referrals’ processing activity."
      }, {
        header: "PAYMENT",
        paragraph: "Once a month, we pay out your earnings using your preferred payment system."
      }]
    }]
  },

  merchant: {
    firstSection: [{
      type: "h1",
      text: "Make Merchant introductions and earn more!"
    }, {
      type: "h3",
      text: "You can sign up in two minutes and instantly start referring iGaming, Forex and Crypto merchants."
    }, {
      type: "h2",
      text: "Why become an FXMB Partner?"
    }, {
      type: "ul",
      text: [
        "Promote an up and coming Industry leading payment technology brand",
        "Multi-Language and Multi-Country Brand",
        "Simplifying how you can make referrals",
        "Clear Analytics and Reporting",
        "Regular Monthly Earnings"
      ]
    }],
    secondSection: [{
      type: "h1",
      text: "Making sense of high risk payment processing in the iGaming, Forex and Crypto space."
    }, {
      type: "h2",
      text: "FXMB Features at a glance:"
    }, {
      type: "ul",
      text: [
        "Providing payment options for high risk industries such as iGaming, Forex and Crypto",
        "Multiple markets including India, LATAM and SE Asia",
        "Multiple Local Payment Methods",
        "Single integration solution - fast and cost effective to set up",
        "Reduce customer frustration and ensure high conversion rates",
        "Customize how online payments are handled & recoup processing costs via in-app exchange",
        "Fast and Secure Settlements via Licensed Partners"
      ]
    }, {
      type: "h3",
      text: "We know how important your referrals are and we will go above and beyond to look after all referrals made by you. We develop our payment software and all relationships for the long term."
    }],
    thirdSection: [{
      type: "h2",
      className: "signup-action-header",
      text: "Referring merchants has never been so easy, send a link to your connections and we will do the rest!"
    }],
    fourthSection: [{
      type: "h4",
      text: "The FXMB Partner programme provides a generous revenue share commission structure that has no limits, you earn alongside us as a business making it a true partnership programme. Whether you are a payments advisor, an online affiliate or someone who is involved in the payments industry you are able to generate income via our industry leading agent partner programme."
    }, {
      type: "h4",
      text: "What is the FXMB Merchant program?"
    }, {
      type: "ol",
      kind: "1",
      text: [{
        header: "GETTING STARTED",
        paragraph: "If you have a website a blog or you purchase internet traffic, you can publish your affiliate link on your site or ad targeting high risk merchants. Alternatively if you have a network of merchants you can send them a link and refer them to us and we will do the rest."
      }, {
        header: "YOUR LINK",
        paragraph: "A referral clicks your link. We tag this referral with your unique ID and assess their requirements. "
      }, {
        header: "REGISTERING REFERRALS",
        paragraph: "The user signs up and starts processing."
      }, {
        header: "PROFIT ACCRUAL",
        paragraph: "You receive up to 25% of our fee according to your clients' processing activity."
      }, {
        header: "PAYMENT",
        paragraph: "Once a month, we pay out your earnings using your preferred payment system."
      }]
    }]
  },

  "white-label": {
    firstSection: [{
      type: "h1",
      text: "Make White Label introductions to StarMids and benefit!"
    }, {
      type: "h3",
      text: "You can sign up in two minutes and instantly start referring iGaming, Forex and Crypto merchants who need to better organize and centralize their payment methods and PSPs by using a StarMids White Label."
    }, {
      type: "h2",
      text: "Why become a Starmids Partner?"
    }, {
      type: "ul",
      text: [
        "Promote an up and coming Industry leading payment technology brand",
        "Multi-Language and Multi-Country Brand",
        "Simplifying how you can make referrals",
        "Clear Analytics and Reporting",
        "Regular Monthly Earnings"
      ]
    }],
    secondSection: [{
      type: "h1",
      text: "Making sense of high risk payment processing in the iGaming, Forex and Crypto space."
    }, {
      type: "h3",
      text: "StarMids payment technology provides a better way to manage online payments and can provide a white label of the software to merchants to better organise their payment methods. This allows the merchant a single point of access to all of their payment acquirers and be able to control them without the need for a technical team which provides huge time and cost savings. In addition the software can be used to generate FX fees and as a result recoup processing costs."
    }, {
      type: "h3",
      text: "We know how important your referrals are and we will go above and beyond to look after all referrals made by you. We develop our payment software and all relationships for the long term."
    }],
    thirdSection: [{
      type: "h2",
      className: "signup-action-header",
      text: "Referring merchants has never been so easy, send a link to your connections and we will do the rest!"
    }],
    fourthSection: [{
      type: "h4",
      text: "The StarMids Partner programme provides a generous revenue share commission structure that has no limits, you earn alongside us as a business making it a true partnership programme. Whether you are a payments advisor, an online affiliate or someone who is involved in the payments industry you are able to generate income via our industry leading agent partner programme."
    }, {
      type: "h4",
      text: "What is the StarMids White Label program?"
    }, {
      type: "ol",
      kind: "1",
      text: [{
        header: "GETTING STARTED",
        paragraph: "If you have a network of merchants you can send them a link and refer them to us and we will do the rest."
      }, {
        header: "YOUR LINK",
        paragraph: "A referral clicks your link. We tag this referral with your unique ID and assess their requirements."
      }, {
        header: "REGISTERING REFERRALS",
        paragraph: "The user signs up and starts processing."
      }, {
        header: "PROFIT ACCRUAL",
        paragraph: "You receive up to 25% of our fee according to your clients' processing activity."
      }, {
        header: "PAYMENT",
        paragraph: "Once a month, we pay out your earnings using your preferred payment system."
      }]
    }]
  },

  psp: {
    firstSection: [{
      type: "h1",
      text: "Make PSP introductions and earn more!"
    }, {
      type: "h3",
      text: "You can sign up in two minutes and instantly start referring existing Payment Service Providers (PSP) or anyone interested in wanting to start a payments business in a fast and cost effective way."
    }, {
      type: "h2",
      text: "Why become a Starmids PSP Partner?"
    }, {
      type: "ul",
      text: [
        "Promote an up and coming Industry leading payment technology brand",
        "Multi-Language and Multi-Country Brand",
        "Simplifying how you can make referrals",
        "Clear Analytics and Reporting",
        "Regular Monthly Earnings"
      ]
    }],
    secondSection: [{
      type: "h1",
      text: "Making sense of high risk payment processing in the iGaming, Forex and Crypto space."
    }, {
      type: "h3",
      text: "StarMids is an industry leading software platform to manage online payments in a way that mitigates risk, reduces overall payment processing fees and that provides a market leading back office system for all stakeholders."
    }, {
      type: "h3",
      text: "We can provide a fully functioning payment white label with all payment methods, allowing resellers enter the market and develop a brand they own. We develop our payment software and all relationships for the long term."
    }],
    thirdSection: [{
      type: "h2",
      className: "signup-action-header",
      text: "Referring has never been so easy, send a link to your connections and we will do the rest!"
    }],
    fourthSection: [{
      type: "h4",
      text: "The StarMids Partner programme provides a generous revenue share commission structure that has no limits, you earn alongside us as a business making it a true partnership programme. Whether you are a payments advisor, an online affiliate or someone who is involved in the payments industry you are able to generate income via our industry leading agent partner programme."
    }, {
      type: "h4",
      text: "What is the StarMids PSP program?"
    }, {
      type: "ol",
      kind: "1",
      text: [{
        header: "GETTING STARTED",
        paragraph: "If you have a network of payment individuals you can send them a link and refer them to us and we will do the rest."
      }, {
        header: "YOUR LINK",
        paragraph: "A referral clicks your link. We tag this referral with your unique ID and assess their requirements."
      }, {
        header: "REGISTERING REFERRALS",
        paragraph: "The agent you refer signs up and starts referring to us."
      }, {
        header: "PROFIT ACCRUAL",
        paragraph: "You receive up to 10% of our fee according to your Agent referrals’ processing activity."
      }, {
        header: "PAYMENT",
        paragraph: "Once a month, we pay out your earnings using your preferred payment system."
      }]
    }]
  }
};