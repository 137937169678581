import React, { Component } from 'react';
import { Column, Row } from 'simple-flexbox';
import { Panel } from "react-bootstrap";
import { NavLink } from 'react-router-dom';

import Table from "@sm/components/custom/Table";

import '@assets/css/clients.css';
import WhiteLabelIcon from '@assets/images/whitelabels.png';

import { crudActions } from "@sm/services/crudActions";

class Companies extends Component {
  state = {
    companies: [],
    searchPattern: "",
    isSuggestionBoxOpen: false,
    access: [],

    columns: [{
      value: "name",
      label: "Company Name"
    }, {
      value: "shortName",
      label: "Company Short Name"
    }, {
      value: "id",
      label: "View",
      centerAligned: true,
      className: "btn view-client",
      route: "/view-white-label",
      isAction: true
    }, {
      value: "id",
      label: "Edit",
      centerAligned: true,
      className: "btn edit-client",
      route: "/edit-white-label",
      isAction: true
    }]
  };

  componentDidMount() {
    crudActions.get("v1/companies").then(companies => {
      if (companies) {
        this.setState({ companies: companies });
      }
    });
  }

  onSuggestionClick(companyName) {
    this.setState({
      searchPattern: companyName,
      isSuggestionBoxOpen: false
    });
  }

  searchSuggests(isSuggestion) {
    const companies = this.state.companies;
    const searchValue = this.state.searchPattern.toLowerCase();
    if (!searchValue) {
      return isSuggestion ? [] : companies;
    }
    const sortedCompanies = companies.filter(company => {
      return company.name.toLowerCase().includes(searchValue) || company.shortName.toLowerCase().includes(searchValue);
    });

    return sortedCompanies;
  }

  isBoxOpen() {
    if (this.state.isSuggestionBoxOpen) {
      return <div
        className='search-suggests'>
        {
          this.searchSuggests(true).map((company, index) => {
            return <div className='suggestion' key={ company.shortName + index } onClick={ () => this.onSuggestionClick(company.name) }>
              {company.name}
            </div>;
          })
        }
      </div>;
    }

    return <div></div>;
  }

  handleSearchChange = (e) => {
    const value = e.target.value;
    this.setState({
      searchPattern: value,
      isSuggestionBoxOpen: false
    });
  };

  render() {
    const { searchPattern, columns } = this.state;

    return (
      <Row flexGrow={ 1 } className="module apidata clients" vertical='start'>
        <Column flexGrow={ 1 }>
          <Row className="header" flexGrow={ 1 } horizontal='space-between' vertical='center'>
            <Column>
              <Row horizontal='center' vertical='center' style={ {paddingLeft: 15} }>
                <img src={ WhiteLabelIcon } alt="" style={ {marginRight: 10} }/>
                White Labels
              </Row>
            </Column>
            <Column horizontal='end'>
              <Row horizontal='end' vertical='center'>
                <Column horizontal='end' style={ {paddingRight: 15} }>
                  <Row horizontal='end' vertical='center' style={ {color: '#ccc', fontSize: '12px'} }>
                    <NavLink to={ `/add-white-label` } className="btn add-merchant-button">
                      ADD WHITE LABEL
                    </NavLink>
                  </Row>
                </Column>
              </Row>
            </Column>
          </Row>

          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
            <Column flexGrow={ 1 } vertical='start' className="panel-block">
              <Panel>
                <Panel.Heading>
                  <Panel.Title>
                      WHITE LABELS INFORMATION
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  <div className="panel-content" style={ {overflow: 'unset'} }>

                    <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' className='search-bar'>
                      <input
                        className='form-control'
                        type='text'
                        value={ searchPattern }
                        placeholder='Search White Label Name'
                        onChange={ this.handleSearchChange }/>
                      {
                        this.isBoxOpen()
                      }
                    </Row>

                    <div className="clients-wrapper">
                      <Table
                        columns={ columns }
                        data={ this.searchSuggests() }
                        isStriped={ true }
                        defaultSortBy={ columns[0].value }/>
                    </div>
                  </div>
                </Panel.Body>
              </Panel>
            </Column>
          </Row>
        </Column>
      </Row>
    );
  }
}

export default Companies;
