import React, { Component } from 'react';
import { Row, Column } from 'simple-flexbox';
import { Panel } from "react-bootstrap";

import Alert from "@sm/components/custom/Alert";
import CustomButton from "@sm/components/custom/Button";
import PageHeader from "@sm/components/PageHeader";
import Select from "@sm/components/custom/Select";

import '@assets/css/sandbox.css';
import NotesIcon from '@assets/images/notes.png';

import { crudActions } from "@sm/services/crudActions";

class Notes extends Component {
  state = {
    whiteLabel: "",
    whiteLabels: [],

    uploadClicked: false,

    showAlert: false,
    alertType: "success",
    alertMessage: ""
  };

  componentDidMount() {
    crudActions.get(`v1/companies/labels`).then(
      (whiteLabels) => {
        if (whiteLabels) {
          this.setState({
            whiteLabels: whiteLabels
          });
        }
      }
    );
  };

  onValueChange = (event) => {
    this.setState({
      whiteLabel: event.value
    });
  };

  onConfirm = () => {
    this.setState({
      showAlert: false,
      alertType: "success",
      alertMessage: ""
    });
  };

  onFileChange = (e) => {
    const file = e.target.files.length ? e.target.files[0] : "";
    if (file) {
      const splittedName = file.name.split(".");
      const extension = splittedName[splittedName.length - 1];
      if (extension !== "pdf") {
        this.setState({
          showAlert: true,
          alertType: "error",
          alertMessage: "Wrong file format."
        });
      } else {
        this.setState({
          fileUploaded: e.target.files.length ? e.target.files[0] : ""
        });
      }
    }
  };

  onUpload = () => {
    const { fileUploaded, whiteLabel } = this.state;
    this.setState({
      uploadClicked: true
    });

    if (fileUploaded && whiteLabel) {
      const formData = new FormData();
      formData.append("file", fileUploaded);
      crudActions.post(`v1/notes/${whiteLabel}`, formData, {}, true).then(
        () => {
          this.setState({
            showAlert: true,
            alertType: "success",
            alertMessage: "Notes successfully uploaded.",
            fileUploaded: "",
            whiteLabel: "",
            uploadClicked: false
          });
        }
      ).catch(
        err => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message
            });
          }
        }
      );
    }
  };

  render() {
    const { whiteLabels, whiteLabel, uploadClicked, fileUploaded, showAlert, alertType, alertMessage } = this.state;

    return (
      <Row flexGrow={ 1 } className="module sandbox" vertical='start'>
        <Column flexGrow={ 1 }>
          <PageHeader
            title={ 'Integration Notes' }
            img={ NotesIcon }/>
          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
            <Column flexGrow={ 1 } vertical='start' className="panel-block">
              <Panel>
                <Panel.Heading>
                  <Panel.Title>
                    UPLOAD INTEGRATION NOTES
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  <div className="panel-content" style={ {overflow: 'unset'} }>
                    <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='end'>
                      <Column flexGrow={ 1 } vertical='start' alignSelf="center" className="input-column">
                        <label> White Label </label>
                        <Select
                          className={ !whiteLabel && uploadClicked ? 'error-field' : "" }
                          value={ whiteLabel }
                          required={ true }
                          clearable={ false }
                          onChange={ (value) => this.onValueChange(value) }
                          options={ whiteLabels }
                        />
                      </Column>
                      <Column flexGrow={ 1 } vertical='start' alignSelf="center" className="empty-column">
                        <span/>
                      </Column>
                      <Column flexGrow={ 1 } vertical='start' alignSelf="center" className="empty-column">
                        <span/>
                      </Column>
                      <Column flexGrow={ 1 } vertical='start' alignSelf="center" className="empty-column">
                        <span/>
                      </Column>
                    </Row>
                    <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='end'>
                      <Column flexGrow={ 1 } vertical='start' alignSelf="end" className="input-column">
                        <label> Upload PDF file </label>

                        <input
                          id="file-upload"
                          type="file" onChange={ (e) => this.onFileChange(e) }
                          accept="application/pdf"/>
                        <label htmlFor="file-upload" className="file-upload">
                          { !fileUploaded ? "No files uploaded yet." : fileUploaded.name}
                          <span className="browse"> Browse... </span>
                        </label>
                      </Column>
                      <Column flexGrow={ 1 } vertical='start' alignSelf="end" className="input-column">
                        <CustomButton
                          title="Upload"
                          type="submit"
                          onClick={ () => this.onUpload() }
                        />
                      </Column>
                    </Row>
                  </div>
                </Panel.Body>
              </Panel>
            </Column>
          </Row>
        </Column>

        {showAlert && (
          <Alert
            show={ showAlert }
            title={ alertType }
            type={ alertType }
            text={ alertMessage }
            confirmButtonColor={ alertType === "success" ? "#187EED" : "#DD6B55" }
            onConfirm={ this.onConfirm }
          />
        )}

      </Row>
    );
  }
}

export default Notes;
