import React, { Component } from 'react';

class Toggler extends Component {
  state = {
    active: false
  };

  componentDidMount() {
    const { active } = this.props;

    if (active) {
      this.setState({
        active: active
      });
    }
  };

  onChangeState = (value) => {
    this.setState({
      active: value
    });
  };

  onClickToggler = () => {
    const { active } = this.state;
    const { onClick, value, id, disabled } = this.props;

    if (!disabled) {
      this.setState({
        active: !active
      });

      if (onClick) {
        onClick(!active, value, id);
      }
    }
  };

  render() {
    const { active } = this.state;
    const { className, disabled } = this.props;

    return (
      <div
        className={ "toggler-container " + (active ? "active" : "") + ` ${className}` }
        disabled={ disabled }
        onClick={ () => this.onClickToggler() }
      >
        <span className="toggler-state"> { active ? "ON" : "OFF" } </span>
        <div className="slider-container"/>
        <div className="slider"/>
      </div>
    );
  }
}

export default Toggler;
