import React, { Component } from 'react';
import { Panel } from "react-bootstrap";

import Spinner from '@sm/components/Spinner';
import Table from "@sm/components/custom/Table";

import '@assets/css/apiData.css';

import { DASHBOARD_AMOUNTS_PER_MID } from '@sm/actions/types';
import { toFormattedNumber } from "@sm/utils/utils";
const store =  require('@sm/reducers/index');

class DashBoardDepositVolumePerMidComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      deposits: undefined,
      loading: true,
      socket: false,

      columns: [{
        value: "midName",
        label: "MID",
        leftAligned: true,
        isNumber: false
      },{
        value: "midId",
        label: "MID ID",
        isNumber: true,
        rightAligned: true,
      }, {
        value: "channelId",
        label: "API",
        isNumber: true,
        rightAligned: true
      }, {
        value: "amount",
        label: "Total",
        isNumber: true,
        rightAligned: true
      }]
    };
  }

  subscribeFunction = null;

  componentDidMount(){
    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().dashboardReducer;

      if (state.update === "LOADING") {
        this.setState({
          loading: true
        });
      }

      if (state.update === DASHBOARD_AMOUNTS_PER_MID && this.props.thisMonth && state.amountsPerMid.socket) {
        this.setState({ deposits: state.amountsPerMid, loading: false, socket: state.amountsPerMid.socket });
        return;
      }

      if (state.update ===  DASHBOARD_AMOUNTS_PER_MID && !state.amountsPerMid.socket) {
        this.setState({ deposits: state.amountsPerMid, loading: false, socket: state.amountsPerMid.socket });
      }
    });
  }

  componentWillUnmount() {
    if (this.subscribeFunction) {
      this.subscribeFunction();
    }
  };

  tableData = () => {
    const { deposits } = this.state;

    if (!deposits) {
      return [];
    }

    deposits.sort((elemA, elemB) => elemA.midName.localeCompare(elemB.midName));

    return deposits.map(elem => {
      return {
        midName: elem.midName,
        midId: elem.midId,
        channelId: elem.channelId,
        amount: elem.currency + " " + toFormattedNumber(elem.amount)
      };
    });
  };

  render() {
    const { deposits, loading, columns } = this.state;
    let sellsWrap = (
      <Panel>
        <Panel.Heading>
          <Panel.Title> PAYMENTS VOLUME PER MID </Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <Spinner smallContainer={ true } />
        </Panel.Body>
      </Panel>
    );

    if (!loading && deposits) {
      sellsWrap = (
        <Panel style={ { height: '100%' } }>
          <Panel.Heading>
            <Panel.Title> PAYMENTS VOLUME PER MID </Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <div className="panel-content table-content ">
              <Table
                columns={ columns }
                headerColor={ "#DFDFDF" }
                data={ this.tableData() }
                isStriped={ false }
              />
            </div>
          </Panel.Body>
        </Panel>
      );
    }
    return sellsWrap;
  }

}

export default DashBoardDepositVolumePerMidComponent;