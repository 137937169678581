import React, { Component } from 'react';
import axios from "axios";
import { BrowserRouter, Route } from 'react-router-dom';
import { Column, Row } from 'simple-flexbox';

import EmptySignupScreen from '@modules/signup/EmptySignupScreen';
import Header from '@sm/components/Header';
import Login from '@modules/login/Login';
import Module from '@sm/components/Module';
import ScrollToTop from '@sm/components/functional-components/ScrollToTop';
import SideBar from '@sm/components/SideBar';
import SignUp from '@modules/signup/SignUp';

import "bootstrap/dist/css/bootstrap.min.css";
import '@assets/css/main.css';

import { frontEndUrl } from "@sm/config";

class App extends Component {
  state = {
    userLoggedIn: false
  };

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.accessToken) {
      this.setState({ userLoggedIn: true });
    }
    if (!localStorage.getItem("user-ip")) {
      axios.get("https://api.ipify.org/?format=text")
        .then(response => {
          if (response) {
            localStorage.setItem("user-ip", response.data);
          }
        });
    }
  }

  isSignUpScreen() {
    const currentLocation = window.location.href;
    return currentLocation.includes(frontEndUrl + "/sign-up");
  }

  render() {
    const { userLoggedIn } = this.state;

    return (
      <div>
        <BrowserRouter>
          <ScrollToTop>
            <Route>
              { !this.isSignUpScreen() ? (
                <Column
                  className={ `starmids content ${userLoggedIn ? 'logged-in' : 'non-logged-in'}` }
                  style={ { zoom: userLoggedIn ? 0.9 : 1} }
                >
                  <Header />
                  <Row flexGrow={ 1 }>

                    { userLoggedIn &&
                    	<Column className="sidebarColumn">
                    	  <SideBar />
                    	</Column>
                    }

                    <Column className="contentColumn" style={ {width: '100%'} }>
                      { userLoggedIn ?  <Module /> : <Login /> }
                    </Column>
                  </Row>
                </Column>
              ) : (
                <Column className="starmids content">
                  <Row flexGrow={ 1 }>
                    <Column className="contentColumn" style={ { width: '100%' } }>
                      <Route exact path="/sign-up" component={ EmptySignupScreen }/>
                      <Route exact path="/sign-up/:product" component={ SignUp }/>
                    </Column>
                  </Row>
                </Column>
              )}
            </Route>
          </ScrollToTop>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;