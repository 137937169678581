import React, { Component } from 'react';
import { Column, Row } from 'simple-flexbox';
import { Redirect, NavLink } from 'react-router-dom';
import { Panel } from "react-bootstrap";

import Alert from "@sm//components/custom/Alert";
import CustomButton from "@sm//components/custom/Button";
import Form from "@sm//core/Form";
import Select from "@sm//components/custom/Select";
import Spinner from '@sm//components/Spinner';

import '@sm//assets/css/apiData.css';
import PSPIcon from '@sm//assets/images/psp.png';

import language from '@sm//assets/lang/language';
import { crudActions } from "@sm//services/crudActions";

class AddTable extends Component {
  state = {
    pspLinks: [{
      name: "ADD DEPOSIT TABLE",
      url: "/providers/add-table/buy",
    }, {
      name: "ADD PAYOUT TABLE",
      url: "/providers/add-table/payout"
    }],

    controls: new Form({
      company: "",
      psp: "",
      method: "",
      currency: ""
    }, [{
      name: "company",
      type: "isNumber",
      rules: {
        required: true
      }
    }, {
      name: "psp",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "method",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "currency",
      type: "isString",
      rules: {
        required: true
      }
    }]),
    generatedControls: {},

    companies: [],
    psps: [],
    methods: [],
    methodsMap: {},

    columns: [{
      "name": "PROFIT_MARGIN",
      "label": "Profit Margin",
      "isPercent": true
    }, {
      "name": "REFUND_MARGIN",
      "label": "Refund Margin",
      "isPercent": true
    }, {
      "name": "REFUND_FEE",
      "label": "Refund Fee",
      "isPercent": true
    }, {
      "name": "TRANSACTION_FEE",
      "label": "Transaction Fee",
      "isPercent": true
    }, {
      "name": "PROCESSING_FEE",
      "label": "Processing Fee",
      "isPercent": true
    }, {
      "name": "TRANSACTION_MARGIN",
      "label": "Transaction Margin",
      "isPercent": true
    }],
    columnValues: {},

    clickMap: {},
    errorsMap: {},

    showAlert: false,
    alertType: "success",
    alertMessage: "",
    saveClicked: false,

    isLoading: true,
    isLoadingTable: true,
    isBurgerOpen: false,
    isGenerateClicked: false,
    showSecondPanel: false
  };

  inputRefs = {};

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);

    this.loadInitialData();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.match && this.props.match) {
      if (prevProps.match.params.type !== this.props.match.params.type) {
        this.setState(this.getInitialState(), this.loadInitialData);
      }
    }
  };

  getInitialState = () => {
    return {
      pspLinks: [{
        name: "ADD DEPOSIT TABLE",
        url: "/providers/add-table/buy",
      }, {
        name: "ADD PAYOUT TABLE",
        url: "/providers/add-table/payout"
      }],

      controls: new Form({
        company: "",
        psp: "",
        method: "",
        currency: ""
      }, [{
        name: "company",
        type: "isNumber",
        rules: {
          required: true
        }
      }, {
        name: "psp",
        type: "isString",
        rules: {
          required: true
        }
      }, {
        name: "method",
        type: "isString",
        rules: {
          required: true
        }
      }, {
        name: "currency",
        type: "isString",
        rules: {
          required: true
        }
      }]),
      generatedControls: {},

      companies: [],
      psps: [],
      methods: [],
      methodsMap: {},

      columns: [{
        "name": "PROFIT_MARGIN",
        "label": "Profit Margin",
        "isPercent": true
      }, {
        "name": "REFUND_MARGIN",
        "label": "Refund Margin",
        "isPercent": true
      }, {
        "name": "REFUND_FEE",
        "label": "Refund Fee",
        "isPercent": true
      }, {
        "name": "TRANSACTION_FEE",
        "label": "Transaction Fee",
        "isPercent": true
      }, {
        "name": "PROCESSING_FEE",
        "label": "Processing Fee",
        "isPercent": true
      }, {
        "name": "TRANSACTION_MARGIN",
        "label": "Transaction Margin",
        "isPercent": true
      }],
      columnValues: {},

      clickMap: {},
      errorsMap: {},

      showAlert: false,
      alertType: "success",
      alertMessage: "",
      saveClicked: false,

      isLoading: true,
      isLoadingTable: true,
      isBurgerOpen: false,
      isGenerateClicked: false,
      showSecondPanel: false
    };
  };

  loadInitialData = () => {
    Promise.all([
      crudActions.get('v1/companies/labels'),
      crudActions.get("v1/psp")
    ]).then(
      (data) => {
        const companies = data[0];
        const psps = data[1];

        this.setState({
          companies,
          psps: psps.map(elem => {
            return {
              value: elem.shortName,
              label: elem.name,
              companyIds: elem.companyIds
            };
          }),
          isLoading: false
        });
      }
    ).catch(
      (err) => {
        if (err && err.message) {
          this.setState({
            showAlert: true,
            alertType: "error",
            alertMessage: err.message
          });
        }
      }
    );
  };

  handleClick = (e) => {
    if ((this.node && this.node.contains(e.target)) || (this.burgerNode && this.burgerNode.contains(e.target))) {
      return;
    }

    this.handleClickOutside();
  };

  handleClickOutside = () => {
    if (!this.state.isBurgerOpen) {
      return;
    }
    this.setState({
      isBurgerOpen: false
    });
  };

  onBurgerClick = () => {
    this.setState({
      isBurgerOpen: !this.state.isBurgerOpen
    });
  };

  filterPsps = () => {
    const { controls, psps } = this.state;
    if (!controls.company) {
      return [];
    }

    return psps.filter(psp => psp.companyIds.includes(controls.company));
  };

  filterCurrencies = () => {
    const { methodsMap, controls } = this.state;
    if (!controls.method) {
      return [];
    }

    return methodsMap[controls.method].map(elem => {
      return {
        value: elem,
        label: elem
      };
    });
  };

  getValue = (value) => {
    if (value === 0) {
      return 0;
    } else if (!value) {
      return "";
    }

    return value;
  };

  onSelectChange = (value, fieldName) => {
    const { controls, isGenerateClicked } = this.state;
    const newValue = value.value;

    controls[fieldName] = newValue;

    if (fieldName === "company") {
      controls.psp = "";
      controls.method = "";
      controls.currency = "";
    } else if (fieldName === "psp") {
      controls.method = "";
      controls.currency = "";
      this.loadDepositMethods();
    }

    if (isGenerateClicked) {
      controls.isFormValid();
    }

    this.setState(controls);
  };

  loadDepositMethods = () => {
    const { controls } = this.state;
    const match = this.props.match;

    if (match) {
      const type = match.params.type.toUpperCase();
      crudActions.get(`v1/psp/methods-filter?company_id=${controls.company}&psp=${controls.psp}&method_type=${type}`).then(
        (data) => {
          if (data) {
            const methodsValues = Object.keys(data);
            const methods = methodsValues.map(elem => {
              return {
                value: elem,
                label: language.en.methods[elem]
              };
            });

            this.setState({
              methods,
              methodsMap: data
            });
          }
        }
      ).catch(
        (err) => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message
            });
          }
        }
      );
    }
  };

  onGenerateTable = () => {
    const { controls } = this.state;
    const match = this.props.match;

    const isValidControls = controls.isFormValid();

    this.setState({
      controls,
      isGenerateClicked: true,
      showSecondPanel: isValidControls && match
    });

    if (isValidControls && match) {
      const type = match.params.type.toUpperCase();

      crudActions.get(`v1/psp/fields?psp=${controls.psp}&method_type=${type}&method=${controls.method}`).then(
        (columns) => {
          if (columns) {
            this.setState({
              columns: columns,
              errorsMap: {},
              clickMap: columns.map(column => column.name).reduce((accumulator, currentElem) => {
                accumulator[currentElem] = false;
                return accumulator;
              }, {}),
              isLoadingTable: false,
              generatedControls: controls.data()
            });
          }
        }
      ).catch(
        (err) => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message,
              isLoadingTable: false,
              showSecondPanel: false
            });
          }
        }
      );
    }
  };

  onKeyPress = (event, fieldName, clickMap) => {
    const { columnValues } = this.state;
    const keyPressed = event.charCode;
    if (keyPressed === 13) {
      this.onTableDataClick(columnValues[fieldName], fieldName);
    }
  };

  setRef = (ref, columnName) => {
    this.inputRefs[columnName] = ref;
  };

  onTableDataClick = (value, columnName) => {
    const { clickMap } = this.state;
    if (value !== 0 && !value) {
      return false;
    }

    clickMap[columnName] = !clickMap[columnName];

    this.setState({
      clickMap
    }, () => {
      const input = this.inputRefs[columnName];
      if (input) {
        input.focus();
      }
    });
  };

  onChangeGeneralSettings = (event, columnName) => {
    const { saveClicked } = this.state;

    this.setState({
      columnValues: Object.assign(this.state.columnValues, {
        [columnName]: parseFloat(event.target.value)
      })
    });

    if (saveClicked) {
      this.checkValidation();
    }
  };

  onSaveTable = () => {
    const { columnValues, generatedControls } = this.state;
    const match = this.props.match;
    const isErrors = this.checkValidation();

    this.setState({
      saveClicked: true
    });

    if (!isErrors && match) {
      const type = match.params.type.toUpperCase();
      crudActions.post("v1/psp/defaults", {
        companyId: generatedControls.company,
        psp: generatedControls.psp,
        paymentMethodType: type,
        paymentMethod: generatedControls.method,
        currency: generatedControls.currency,
        defaults: columnValues
      }).then(
        () => {
          this.setState({
            showAlert: true,
            alertType: "success",
            alertMessage: "Settings successfully saved."
          });
        }
      ).catch(
        (err) => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message
            });
          }
        }
      );
    }
  };

  onConfirm = () => {
    this.setState({
      showAlert: false,
      alertType: "success",
      alertMessage: "",
      redirectToPsps: this.state.showAlert && this.state.alertType === "success"
    });
  };

  checkValidation = () => {
    const { errorsMap, clickMap, columnValues, columns } = this.state;
    let isErrors = false;

    columns.forEach(
      column => {
        const columnValue = columnValues[column.name];

        if (column.isPercent) {
          errorsMap[column.name] = columnValue < 0 || columnValue > 100 || (!columnValue && columnValue !== 0);
        } else if (column.name === "MIN_AMOUNT") {
          errorsMap[column.name] = columnValue > columnValues.MAX_AMOUNT || columnValue < 0 || (!columnValue && columnValue !== 0);
        } else if (column.name === "MAX_AMOUNT") {
          errorsMap[column.name] = columnValue < columnValues.MIN_AMOUNT || columnValue < 0 || (!columnValue && columnValue !== 0);
        } else {
          errorsMap[column.name] = columnValue < 0 || (!columnValue && columnValue !== 0);
        }

        if (errorsMap[column.name]) {
          isErrors = true;
          clickMap[column.name] = false;
        }
      }
    );

    this.setState({
      errorsMap,
      clickMap
    });

    return isErrors;
  };

  render() {
    const { controls, companies, isBurgerOpen, pspLinks, methods, isLoading, columnValues, generatedControls, redirectToPsps,
      showAlert, alertType, alertMessage, showSecondPanel, columns, isLoadingTable, errorsMap, clickMap } = this.state;
    const match = this.props.match;

    const possibleMethods = ["buy", "payout"];

    let methodType = "";
    if (match) {
      methodType = match.params.type;
    }

    if (!possibleMethods.some(elem => elem === methodType)) {
      return <Redirect to={ '/providers/add-table/buy' }/>;
    }

    if (redirectToPsps) {
      return <Redirect to={ '/providers' }/>;
    }

    return (
      <Row flexGrow={ 1 } className="module apidata psps" vertical='start'>
        <Column flexGrow={ 1 }>
          <Row className="header" flexGrow={ 1 } horizontal='space-between' vertical='center'>
            <Column>
              <Row horizontal='center' vertical='center' style={ {paddingLeft: 15} }>
                <img src={ PSPIcon } alt="" style={ {marginRight: 10} }/>
                Add Table
              </Row>
            </Column>
            <Column horizontal='end'>
              <Row horizontal='end' vertical='center'>
                <Column horizontal='end' style={ {paddingRight: 15} }>
                  <Row horizontal='end' vertical='center' style={ {color: '#ccc', fontSize: '12px'} }>
                    <NavLink to={ '/providers/add-table/buy' } className="btn add-deposit-button">
                      ADD DEPOSIT TABLE
                    </NavLink>
                    <NavLink to={ '/providers/add-table/payout' } className="btn add-payout-button">
                      ADD PAYOUT TABLE
                    </NavLink>
                    <div ref={ node => this.burgerNode = node } className={ "burger-container " + (isBurgerOpen ? "change" : "") } style={ { float: "right" } }  onClick={ this.onBurgerClick }>
                      <div className="burger-bar1"></div>
                      <div className="burger-bar2"></div>
                      <div className="burger-bar3"></div>
                    </div>
                    { isBurgerOpen && <div ref={ node => this.node = node } className="burger-content">
                      { pspLinks.map((link, i) => {
                        return <div key={ i } className="link-item-container">
                          <NavLink to={ link.url } className="link-item" onClick={ () => this.handleClickOutside() }> {link.name} </NavLink>
                        </div>;
                      }) }
                    </div>
                    }
                  </Row>
                </Column>
              </Row>
            </Column>
          </Row>
          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
            <Column flexGrow={ 1 } vertical='start' className="panel-block">
              <Panel>
                <Panel.Heading>
                  PROVIDER TABLE SETUP
                </Panel.Heading>
                <Panel.Body>
                  { isLoading ? <div style={ { width: "100%", height: "400px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                    <Spinner smallContainer={ true } />
                  </div> : <div className="panel-content" style={ {overflow: 'unset'} }>
                    <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                      <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                        <label> White Label </label>
                        <Select
                          id="company"
                          name="company"
                          value={ controls.company || "" }
                          className={ controls.errors.has('company') ? 'error-field' : "" }
                          required={ true }
                          clearable={ false }
                          onChange={ (value) => this.onSelectChange(value, "company") }
                          options={ companies }
                        />
                      </Column>
                      <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                        <label> Provider </label>
                        <Select
                          id="psp"
                          name="psp"
                          value={ controls.psp || "" }
                          className={ controls.errors.has('psp') ? 'error-field' : "" }
                          required={ true }
                          clearable={ false }
                          disabled={ !controls.company }
                          onChange={ (value) => this.onSelectChange(value, "psp") }
                          options={ this.filterPsps() }
                        />
                      </Column>
                      <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                        <label> { methodType === "buy" ? "Deposit" : "Payout" } Method </label>
                        <Select
                          id="method"
                          name="method"
                          value={ controls.method || "" }
                          className={ controls.errors.has('method') ? 'error-field' : "" }
                          required={ true }
                          disabled={ !controls.psp || !controls.company }
                          clearable={ false }
                          onChange={ (value) => this.onSelectChange(value, "method") }
                          options={ methods }
                        />
                      </Column>
                      <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                        <label> Currency </label>
                        <Select
                          id="currency"
                          name="currency"
                          value={ controls.currency || "" }
                          className={ controls.errors.has('currency') ? 'error-field' : "" }
                          required={ true }
                          disabled={ !controls.psp || !controls.company || !controls.method }
                          clearable={ false }
                          onChange={ (value) => this.onSelectChange(value, "currency") }
                          options={ this.filterCurrencies() }
                        />
                      </Column>
                    </Row>
                    <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                      <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                        <span/>
                      </Column>
                      <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                        <span/>
                      </Column>
                      <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                        <span/>
                      </Column>
                      <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                        <CustomButton
                          title="Generate"
                          type="button"
                          onClick={ () => this.onGenerateTable() }
                        />
                      </Column>
                    </Row>
                  </div> }
                </Panel.Body>
              </Panel>
              { showSecondPanel && <Panel>
                <Panel.Heading>
                  FEE SETTINGS
                </Panel.Heading>
                <Panel.Body>
                  { isLoadingTable ? (
                    <div style={ { width: "100%", height: "400px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                      <Spinner smallContainer={ true } />
                    </div>
                  ) : (
                    <div className="panel-content" style={ {overflow: 'unset'} }>
                      <div className="settings-table-wrapper">
                        <table className="table table-striped settings-table">
                          <thead>
                            <tr>
                              <th> { methodType === "buy" ? 'Payment' : "Payout" } Method </th>
                              <th> Currency </th>
                              { columns.map(column => <th key={ column.name }> {column.label} </th>) }
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td> { language.en.methods[generatedControls.method] } </td>
                              <td> { generatedControls.currency } </td>
                              {columns.map(column => {
                                return (
                                  <td
                                    key={ column.name }
                                    onDoubleClick={ () => this.onTableDataClick(columnValues[column.name], column.name) }
                                    className="td-element">
                                    {clickMap[column.name] ? (
                                      <span>
                                        { this.getValue(columnValues[column.name]) }
                                      </span>
                                    ) : (
                                      <input
                                        className={ `table-input ${errorsMap[column.name] ? "td-error" : ""}` }
                                        type="number" value={ this.getValue(columnValues[column.name]) }
                                        ref={ (input) => this.setRef(input, column.name) }
                                        onKeyPress={ (event) => this.onKeyPress(event, column.name, clickMap) }
                                        onChange={ (event) => this.onChangeGeneralSettings(event, column.name) }
                                        onBlur={ () => this.onTableDataClick(columnValues[column.name], column.name) }
                                        onDoubleClick={ (e) => e.stopPropagation() }
                                      />
                                    )}
                                    {column.isPercent && (
                                      <span className="percent-sign"> % </span>
                                    )}
                                  </td>
                                );
                              })}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <span/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <span/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <span/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <CustomButton
                            title="Save"
                            type="button"
                            onClick={ () => this.onSaveTable() }
                          />
                        </Column>
                      </Row>
                    </div>
                  )}
                </Panel.Body>
              </Panel> }
            </Column>
          </Row>
        </Column>

        {showAlert && (
          <Alert
            show={ showAlert }
            title={ alertType }
            type={ alertType }
            text={ alertMessage }
            confirmButtonColor="#187EED"
            onConfirm={ this.onConfirm }
          />
        )}

      </Row>
    );
  }
}

export default AddTable;
