import React, { Component } from "react";
import { Panel } from "react-bootstrap";

import Spinner from "@sm/components/Spinner";
import Table from "@sm/components/custom/Table";

import "@assets/css/apiData.css";

import { DASHBOARD_SETTLEMENTS } from "@sm/actions/types";
import { toFormattedNumber } from "@sm/utils/utils";
const store = require("@sm/reducers/index");

class DashBoardSettlementsComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      settlements: undefined,
      loading: true,
      socket: false,

      columns: [
        {
          value: "merchantName",
          label: "Merchant Name",
          leftAligned: true,
          isNumber: false,
        },
        {
          value: "merchantId",
          label: "Merchant ID",
          isNumber: true,
          rightAligned: true,
        },
        {
          value: "currency",
          label: "Currency",
          isNumber: false,
          rightAligned: true,
        },
        {
          value: "requestDate",
          label: "Request Date",
          isNumber: true,
          rightAligned: true,
        },
        {
          value: "amount",
          label: "Amount",
          isNumber: true,
          rightAligned: true,
        },
      ],
    };
  }

  subscribeFunction = null;

  componentDidMount() {
    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().dashboardReducer;

      if (state.update === "LOADING") {
        this.setState({
          loading: true,
        });
      }

      if (
        state.update === DASHBOARD_SETTLEMENTS &&
        this.props.thisMonth &&
        state.settlements.socket
      ) {
        this.setState({
          settlements: state.settlements,
          loading: false,
          socket: state.settlements.socket,
        });
        return;
      }

      if (state.update === DASHBOARD_SETTLEMENTS && !state.settlements.socket) {
        this.setState({
          settlements: state.settlements,
          loading: false,
          socket: state.settlements.socket,
        });
      }
    });
  }

  componentWillUnmount() {
    if (this.subscribeFunction) {
      this.subscribeFunction();
    }
  }

  tableData = () => {
    const { settlements } = this.state;

    if (!settlements) {
      return [];
    }

    return settlements.map((elem) => {
      return {
        merchantName: elem.merchantName,
        amount: toFormattedNumber(elem.amount),
        merchantId: elem.merchantId,
        currency: elem.currency,
        requestDate: elem.requestDate,
      };
    });
  };

  render() {
    const { settlements, loading, columns } = this.state;
    let sellsWrap = (
      <Panel>
        <Panel.Heading>
          <Panel.Title> PENDING SETTLEMENT REQUESTS </Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <Spinner smallContainer={ true } />
        </Panel.Body>
      </Panel>
    );

    if (!loading && settlements) {
      sellsWrap = (
        <Panel style={ { height: "100%" } }>
          <Panel.Heading>
            <Panel.Title> PENDING SETTLEMENT REQUESTS </Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <div className="panel-content table-content ">
              <Table
                columns={ columns }
                headerColor={ "#DFDFDF" }
                data={ this.tableData() }
                isStriped={ false }
              />
            </div>
          </Panel.Body>
        </Panel>
      );
    }
    return sellsWrap;
  }
}

export default DashBoardSettlementsComponent;
