import { combineReducers, createStore, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk';

import authReducer from '@sm/reducers/authReducer';
import dashboardReducer from '@sm/reducers/dashboardReducer';
import messageReducer from '@sm/reducers/messageReducer';

const reducer = combineReducers({ authReducer, dashboardReducer, message: messageReducer});
const createStoreWithMiddleware = applyMiddleware(
  thunk // lets us dispatch() functions
)(createStore);

const store = createStoreWithMiddleware(reducer);

export default store;