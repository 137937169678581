import React, { Component } from 'react';
import { Column, Row } from 'simple-flexbox';

import ManageMid from '@modules/mids/ManageMid';

class ViewMid extends Component {
  state = {};

  render() {
    return <Row flexGrow={ 1 } className='module apidata viewClient' vertical='start'>
      <Column flexGrow={ 1 }>
        <ManageMid midId={ this.props.match.params.midId } viewOrEdit={ 1 }/>
      </Column>
    </Row>;
  }
}

export default ViewMid;