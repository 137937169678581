import React, { Component } from 'react';

import PageHeader from "@sm/components/PageHeader";

import '@assets/css/apiData.css';
import dashboardIcon from '@assets/images/dashboardIcon.png';

class LastUpdateComponent extends Component {
  state = {
    pageTitle: 'Dashboard'
  };

  render() {
    const { pageTitle } = this.state;
    const { lastUpdate } = this.props;

    return (
      <PageHeader
        title={ pageTitle }
        lastUpdate={ lastUpdate }
        img={ dashboardIcon }
      />
    );
  }
}

export default LastUpdateComponent;