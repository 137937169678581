import React, { Component } from 'react';
import { Column, Row } from 'simple-flexbox';
import { Panel } from "react-bootstrap";
import { NavLink } from 'react-router-dom';

import Table from '@sm/components/custom/Table';

import '@assets/css/clients.css';
import PSPIconSingle from '@assets/images/psp_single_wl.png';

import { crudActions } from "@sm/services/crudActions";
const store =  require('@sm/reducers/index');

class PSPs extends Component {
  state = {
    psps: [],
    companies: [],
    searchPattern: "",
    access: [],
    isSuggestionBoxOpen: false,

    columns: [{
      value: "name",
      label: "Provider Name"
    },{
      value: "id",
      label: "API"
    }, store.default.getState().authReducer.roleId === "MASTER_TECH" && {
      value: "shortName",
      label: "Provider Short Name"
    }, {
      value: "companyIds",
      label: "White Labels"
    }, {
      value: "active",
      label: "Active / Inactive"
    },{
      value: "id",
      label: "View",
      centerAligned: true,
      className: "btn view-client",
      route: "/view-provider",
      isAction: true
    }]
  };

  componentDidMount() {
    const storeState = store.default.getState().authReducer;
    if (storeState.access) {
      this.setState({
        access: storeState.access
      });
    }
    Promise.all([
      crudActions.get("v1/companies/labels"),
      crudActions.get("v1/psp")
    ]).then(data => {
      const companies = data[0];
      const psps = data[1];
      if (companies && psps) {
        this.setState({ companies: companies, psps: psps });
      }
    });
  };

  onSuggestionClick(pspName) {
    this.setState({
      searchPattern: pspName,
      isSuggestionBoxOpen: false
    });
  };

  searchSuggests(isSuggestion) {
    let { psps } = this.state;
    const { searchPattern } = this.state;

    psps = psps.map(psp => {
      return Object.assign({}, psp, {
        companyIds: this.formatWhiteLabels(psp.companyIds),
        active: psp.active ? "Active" : "Inactive"
      });
    });

    const searchValue = searchPattern.toLowerCase();
    if (!searchValue) {
      return isSuggestion ? [] : psps;
    }
    const sortedPsps = psps.filter(psp => {
      return psp.name.toLowerCase().includes(searchValue) || psp.shortName.toLowerCase().includes(searchValue) || psp.id.toString().includes(searchValue);
    });

    return sortedPsps;
  };

  isBoxOpen() {
    if (this.state.isSuggestionBoxOpen) {
      return <div
        className='search-suggests'>
        {
          this.searchSuggests(true).map((psp, index) => {
            return <div className='suggestion' key={ psp.shortName + index } onClick={ () => this.onSuggestionClick(psp.name) }>
              {psp.name}
            </div>;
          })
        }
      </div>;
    }

    return <div></div>;
  };

  handleSearchChange = (e) => {
    const value = e.target.value;
    this.setState({
      searchPattern: value,
      isSuggestionBoxOpen: false
    });
  };

  formatWhiteLabels = (whiteLabels) => {
    const { companies } = this.state;

    if (companies.length === 1) {
      if (whiteLabels.length !== 1) {
        return [];
      }
    }

    return whiteLabels.map(elem => {
      return companies.find(company => company.value === elem).label;
    }).join(", ");
  };

  checkPageAccess = (permissionName) => {
    const { access } = this.state;
    const foundPermission = access.find(elem => elem.permission === permissionName);

    if (!foundPermission) {
      return false;
    }

    return foundPermission.state;
  };

  addNewColumn(columns) {
    // eslint-disable-next-line eqeqeq
    if (columns.find((elem) => elem.label == "Edit")) {
      return columns;
    }
    columns.push({
      value: "id",
      label: "Edit",
      centerAligned: true,
      className: "btn edit-client",
      route: "/edit-provider",
      isAction: true
    });
    return columns;
  }
  render() {
    const { columns, searchPattern } = this.state;
    if (this.checkPageAccess("PSP_EDIT")) {
      this.addNewColumn(columns);
    }
    return (
      <Row flexGrow={ 1 } className="module apidata clients" vertical='start'>
        <Column flexGrow={ 1 }>
          <Row className="header" flexGrow={ 1 } horizontal='space-between' vertical='center'>
            <Column>
              <Row horizontal='center' vertical='center' style={ { paddingLeft: 15 } }>
                <img src={ PSPIconSingle } alt="" style={ { marginRight: 10 } } />
                Providers
              </Row>
            </Column>
            <Column horizontal='end'>
              <Row horizontal='end' vertical='center'>
                {store.default.getState().authReducer.roleId === "MASTER_TECH" && <Column horizontal='end' style={ { paddingRight: 15 } }>
                  <Row horizontal='end' vertical='center' style={ { color: '#ccc', fontSize: '12px' } }>
                    <NavLink to={ `/add-provider` } className="btn add-merchant-button">
                      ADD PROVIDER
                    </NavLink>
                  </Row>
                </Column>}
              </Row>
            </Column>
          </Row>

          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
            <Column flexGrow={ 1 } vertical='start' className="panel-block">
              <Panel>
                <Panel.Heading>
                  <Panel.Title>
                    PROVIDERS INFORMATION
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  <div className="panel-content" style={ { overflow: 'unset' } }>

                    <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' className='search-bar'>
                      <input
                        className='form-control'
                        type='text'
                        value={ searchPattern }
                        placeholder="Search Provider Name"
                        onChange={ this.handleSearchChange } />
                      {
                        this.isBoxOpen()
                      }
                    </Row>

                    <div className="clients-wrapper">
                      <Table
                        columns={ columns }
                        data={ this.searchSuggests() }
                        isStriped={ true }
                        defaultSortBy={ columns[0].value } />
                    </div>
                  </div>
                </Panel.Body>
              </Panel>
            </Column>
          </Row>
        </Column>
      </Row>
    );
  }
}

export default PSPs;
