import React, { Component } from 'react';
import { Column, Row } from 'simple-flexbox';

import { crudActions } from "@sm/services/crudActions";

class WithheldFunds extends Component {
  state = {
    funds: [],
    newItem: {},
    defaultItem: {
      amount: "",
      date: this.props.savedData.date,
      currency: this.props.savedData.currency
    },
    showError: false,
    errorMessage: "",
    newRowClicked: false
  };

  componentDidMount() {
    const { defaultItem } = this.state;
    const { funds } = this.props;
    this.setState({
      newItem: { ...defaultItem }
    });
    if (funds) {
      this.setState({
        funds: funds
      });
    }
  }

  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (oldProps.funds.length !== newProps.funds.length) {
      this.setState({
        funds: newProps.funds,
        newItem: {
          date: newProps.savedData.date,
          currency: newProps.savedData.currency
        }
      });
    }

    if (oldProps.update !== newProps.update) {
      this.setState({
        newRowClicked: false
      });
    }
  }

  onFundsItemChange = (event, index, fieldId) => {
    const { funds } = this.props;
    const value = event.target.value.trim();

    funds[index][fieldId] = value;

    this.setState({
      funds
    });
  };

  onTemporaryItemChange = (sendItem) => {
    const { newItem } = this.state;
    this.props.onTemporaryChange(sendItem || newItem);
  };

  onInputItemChange = (event, fieldId) => {
    const { savedData } = this.props;
    const value = event.target.value.trim();

    this.setState({
      newItem: Object.assign(this.state.newItem, {
        [fieldId]: value,
        date: savedData.date,
        currency: savedData.currency
      })
    }, () => {
      const { newItem, defaultItem } = this.state;
      if (newItem.amount) {
        this.onTemporaryItemChange();
      } else {
        this.onTemporaryItemChange(defaultItem);
      }
    });
  };

  onAddItem = () => {
    const { funds, newItem, newRowClicked, defaultItem } = this.state;

    if (!newRowClicked && funds.length > 0) {
      this.setState({
        newRowClicked: true
      });

      return;
    }

    funds.push(newItem);
    this.props.onChange(funds);
    this.onTemporaryItemChange(defaultItem);

    this.setState({
      funds: funds,
      newItem: { ...defaultItem },
      showError: false,
      errorMessage: "",
      newRowClicked: true
    });
  };

  onDeleteItem = (fund, index) => {
    const { funds, defaultItem } = this.state;

    if (!fund.id) {
      const isInArray = funds.includes(fund);
      if (isInArray) {
        funds.splice(index, 1);
        this.props.onChange(funds);
      }
      this.setState({
        newRowClicked: index === funds.length || isInArray,
        ...(index === funds.length && { newItem: { ...defaultItem }}),
        funds,
        showError: false,
        errorMessage: ""
      });
      return;
    }

    if (fund.id) {
      crudActions.remove(`v1/data/input/withheld-funds/${fund.id}`)
        .then(() => {
          funds.splice(index, 1);
          this.props.onChange(funds);
          this.setState({
            funds
          });
        })
        .catch(() => {
          this.setState({
            showError: true,
            errorMessage: "Please, try again."
          });
        });
    } else {
      funds.splice(index, 1);
      this.props.onChange(funds);
      this.setState({
        funds
      });
    }
  };

  render() {
    const { funds, newItem, newRowClicked, showError, errorMessage } = this.state;
    const props = this.props;
    
    return (
      <Column className='manual-data-input' flexGrow={ 1 } vertical='start' alignSelf='start'>
        <Column className='manual-data-input-body' flexGrow={ 1 } vertical='start' alignSelf='start'>
          {funds.map((elem, i) => {
            return <Row key={ i } flexGrow={ 1 } horizontal='start' className="selected-items">
              <Column className="manual-data-input-column short">
                { i === 0 && <label> Date </label> }
                <input
                  readOnly
                  disabled
                  className="form-control"
                  value={ elem.date }
                />
              </Column>
              <Column className="manual-data-input-column short">
                { i === 0 && <label> Currency </label> }
                <input
                  readOnly
                  disabled
                  className="form-control"
                  value={ elem.currency }
                />
              </Column>
              <Column className="manual-data-input-column">
                { i === 0 && <label> Amount </label> }
                <input
                  readOnly={ elem.id }
                  disabled={ elem.id }
                  className="form-control"
                  value={ elem.amount }
                  onChange={ (event) => this.onFundsItemChange(event, i, "amount") }
                />
              </Column>
              { props.editable && (
                <Row className="actions" flexGrow={ 1 } horizontal='start' wrap={ true } vertical='end'>
                  <button
                    className="delete"
                    onClick={ () => this.onDeleteItem(elem, i) }>
                    X
                  </button>
                  { (!newRowClicked && i === funds.length - 1) && (
                    <button
                      className="add"
                      onClick={ () => this.onAddItem() }
                    >
                      <span className="plus"> + </span>
                    </button>
                  )}
                </Row>
              )}
            </Row>;
          })}

          {(funds.length === 0 || newRowClicked) && props.editable && (
            <Row style={ { width: "100%", marginTop: "10px" } } flexGrow={ 1 } horizontal='start'>
              <Column className="manual-data-input-column short">
                { funds.length === 0 && <label> Date </label> }
                <input
                  className="form-control"
                  readOnly
                  disabled
                  value={ newItem.date || '' }
                  onChange={ (event) => this.onInputItemChange(event, "date") }
                />
              </Column>
              <Column className="manual-data-input-column short">
                { funds.length === 0 && <label> Currency </label> }
                <input
                  className="form-control"
                  readOnly
                  disabled
                  value={ newItem.currency || '' }
                  onChange={ (event) => this.onInputItemChange(event, "currency") }
                />
              </Column>
              <Column className="manual-data-input-column">
                { funds.length === 0 && <label> Amount </label> }
                <input
                  className="form-control"
                  placeholder="Type amount here..."
                  value={ newItem.amount || '' }
                  onChange={ (event) => this.onInputItemChange(event, "amount") }
                />
              </Column>
              <Row className="actions" flexGrow={ 1 } horizontal='start' vertical='end'>
                { funds.length > 0 && (
                  <button
                    className="delete"
                    onClick={ () => this.onDeleteItem(newItem, funds.length) }
                  >
                    X
                  </button>
                )}
                <button
                  className="add"
                  onClick={ () => this.onAddItem() }
                >
                  <span className="plus"> + </span>
                </button>
              </Row>
            </Row>
          )}
          <Column style={ { width: '100%' } }>
            { showError && <span className="error-message"> { errorMessage } </span> }
          </Column>
        </Column>
      </Column>
    );
  };
};

export default WithheldFunds;