import React, { Component } from 'react';
import { Column, Row } from 'simple-flexbox';
import { Panel } from "react-bootstrap";
import { NavLink } from 'react-router-dom';

import CustomButton from "@sm/components/custom/Button";
import Select from "@sm/components/custom/Select";
import Table from "@sm/components/custom/Table";

import '@assets/css/clients.css';
import MerchantsIcon from '@assets/images/merchants.png';

import { crudActions } from "@sm/services/crudActions";
import { FETCH_PERMISSIONS } from '@sm/actions/types';
const store =  require('@sm/reducers/index');

class Clients extends Component {
  state = {
    clients: [],
    companies: [],
    searchPattern: "",
    selectedCompany: "",
    tempCompany: "",
    isSuggestionBoxOpen: false,
    showMerchants: false,
    access: [],
    roleId: ""
  };

  subscribeFunction = null;

  componentDidMount() {
    const storeState = store.default.getState().authReducer;
    if (storeState.access) {
      const roleId = storeState.roleId;
      this.setState({
        access: storeState.access,
        roleId: roleId,
        showMerchants: roleId === "WL_ADMIN" || roleId === "WL_USER"
      });
    }

    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().authReducer;

      if (state.userUpdate === FETCH_PERMISSIONS) {
        const roleId = storeState.roleId;
        this.setState({
          access: state.access,
          roleId: roleId,
          showMerchants: roleId === "WL_ADMIN" || roleId === "WL_USER"
        });
      }
    });

    crudActions.get('v1/companies/labels').then(
      (companies) => {
        if (companies) {
          this.setState({
            companies: companies,
            tempCompany: companies[0].value
          });
        }
      }
    );

    crudActions.get("v1/clients").then(clients => {
      if (clients) {
        this.setState({ clients: clients });
      }
    });
  }

  componentWillUnmount() {
    if (this.subscribeFunction) {
      this.subscribeFunction();
    }
  };

  onSuggestionClick(clientName) {
    this.setState({
      searchPattern: clientName,
      isSuggestionBoxOpen: false
    });
  }

  tableColumns = () => {
    const columns = [{
      value: "name",
      label: "Merchant Name"
    }, {
      value: "whiteLabel",
      label: "White Label"
    }, {
      value: "shortName",
      label: "Company Name"
    }, {
      value: "email",
      label: "Email"
    }, {
      value: "twoFactorEnabled",
      label: "2FA"
    }, {
      value: "id",
      label: "View",
      centerAligned: true,
      className: "btn view-client",
      route: "/view-merchant",
      isAction: true
    }];

    if (this.checkPageAccess("MERCHANTS_EDIT")) {
      columns.push({
        value: "id",
        label: "Edit",
        centerAligned: true,
        className: "btn edit-client",
        route: "/edit-merchant",
        isAction: true
      });
    }

    return columns;
  };

  searchSuggests(isSuggestion) {
    const { clients, searchPattern, selectedCompany } = this.state;
    const searchValue = searchPattern.toLowerCase();

    let sortedClients = clients;
    if (selectedCompany) {
      sortedClients = clients.filter(client => client.companyId === selectedCompany);
    }

    if (!searchValue) {
      return isSuggestion ? [] : this.prepareTableData(sortedClients);
    }

    sortedClients = sortedClients.filter(client => {
      return client.name.toLowerCase().includes(searchValue);
    });

    return this.prepareTableData(sortedClients);
  }

  prepareTableData = (sortedClients) => {
    return sortedClients.map(client => {
      return Object.assign({}, client, {
        twoFactorEnabled: client.twoFactorEnabled !== "OFF" ? "On" : "Off"
      });
    });
  };

  isBoxOpen() {
    if (this.state.isSuggestionBoxOpen) {
      return <div
        className='search-suggests'>
        {
          this.searchSuggests(true).map((client, index) => {
            return <div className='suggestion' key={ client.shortName + index } onClick={ () => this.onSuggestionClick(client.name) }>
              {client.name}
            </div>;
          })
        }
      </div>;
    }

    return <div></div>;
  }

  handleSearchChange = (e) => {
    const value = e.target.value;
    this.setState({
      searchPattern: value,
      isSuggestionBoxOpen: false
    });
  };

  checkPageAccess = (permissionName) => {
    const { access } = this.state;
    const foundPermission = access.find(elem => elem.permission === permissionName);
    if (!foundPermission) {
      return false;
    }

    return foundPermission.state;
  };

  onCompanyChange = (event) => {
    this.setState({
      tempCompany: event.value
    });
  };

  onUpdateClick = () => {
    const { tempCompany } = this.state;
    if (tempCompany) {
      this.setState({
        selectedCompany: tempCompany,
        showMerchants: true
      });
    }
  };

  render() {
    const { roleId, tempCompany, companies, showMerchants, searchPattern } = this.state;
    const columns = this.tableColumns();

    return (
      <Row flexGrow={ 1 } className="module apidata clients" vertical='start'>
        <Column flexGrow={ 1 }>
          <Row className="header" flexGrow={ 1 } horizontal='space-between' vertical='center'>
            <Column>
              <Row horizontal='center' vertical='center' style={ { paddingLeft: 15 } }>
                <img src={ MerchantsIcon } alt="" style={ { marginRight: 10 } }/>
                  Merchants
              </Row>
            </Column>
            <Column horizontal='end'>
              <Row horizontal='end' vertical='center'>
                { this.checkPageAccess("MERCHANTS_EDIT") && (
                  <Column horizontal='end' style={ { paddingRight: 15 } }>
                    <Row horizontal='end' vertical='center' style={ { color: '#ccc', fontSize: '12px' } }>
                      <NavLink to={ `/add-merchant` } className="btn add-merchant-button">
                        ADD MERCHANT
                      </NavLink>
                    </Row>
                  </Column>
                )}
              </Row>
            </Column>
          </Row>

          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
            <Column flexGrow={ 1 } vertical='start' className="panel-block">
              { roleId !== "WL_ADMIN" && roleId !== "WL_USER" && (
                <Panel>
                  <Panel.Heading>
                    <Panel.Title>
                      SEARCH MERCHANTS
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <div className="panel-content" style={ {overflow: 'unset'} }>
                      <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='end'>
                        <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='end'>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <label> White Label </label>
                            <Select
                              name="company"
                              value={ tempCompany || '' }
                              required={ true }
                              clearable={ false }
                              onChange={ (value) => this.onCompanyChange(value) }
                              options={ companies }
                            />
                          </Column>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <span/>
                          </Column>
                        </Row>
                        <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='end'>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <span/>
                          </Column>
                          <Column flexGrow={ 1 } vertical='start' className="input-column">
                            <CustomButton
                              title="Update"
                              type="submit"
                              onClick={ () => this.onUpdateClick() }
                            />
                          </Column>
                        </Row>
                      </Row>
                    </div>
                  </Panel.Body>
                </Panel>
              )}

              { showMerchants && (
                <Panel>
                  <Panel.Heading>
                    <Panel.Title>
                      MERCHANT INFORMATION
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <div className="panel-content" style={ {overflow: 'unset'} }>

                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' className='search-bar'>
                        <input
                          className='form-control'
                          type='text'
                          value={ searchPattern }
                          placeholder='Search Merchant Name'
                          onChange={ this.handleSearchChange }
                        />
                        { this.isBoxOpen() }
                      </Row>

                      <div className="clients-wrapper">
                        <Table
                          columns={ columns }
                          data={ this.searchSuggests() }
                          isStriped={ true }
                          defaultSortBy={ columns[0].value }
                          useArrow={ true }
                        />
                      </div>
                    </div>
                  </Panel.Body>
                </Panel>
              )}
            </Column>
          </Row>
        </Column>
      </Row>
    );
  }
}

export default Clients;
