import React, { Component } from 'react';
import { Column, Row } from 'simple-flexbox';
import { Panel, Button} from "react-bootstrap";
import Pagination from '@mui/material/Pagination';
import Datetime from 'react-datetime';
import DatePicker from 'react-datepicker';
import { saveAs } from 'file-saver';
import { NavLink } from 'react-router-dom';

import Dropdowns from '@modules/apiData/dropdowns';
import Form from "@sm/core/Form";
import Modal from '@sm/components/custom/Modal';
import Multiselect from "@sm/components/custom/customMultiselect";
import Select from '@sm/components/custom/Select';
import Spinner from '@sm/components/Spinner';
import Table from "@sm/components/custom/Table";

import '@assets/css/logs.css';
import UserManagementIcon from '@assets/images/user-management.png';
import Calendar from '@assets/images/calendar.png';

import { crudActions } from "@sm/services/crudActions";
import { convertUTCToLocalDate, convertLocalToUTCDate } from "@sm/utils/utils";

const INTEGER_MAX = 2147483647;

class Logs extends Component {
  state = {
    controls: new Form({
      whiteLabelId: "",
      userLevels: [],
      userIds: [],
      eventTypes: [],
      fromDate: this.getPeriod(0).from,
      toDate: this.getPeriod(0).to,
    }, [{
      name: "whiteLabelId",
      type: "isNumber",
      rules: {
        required: true
      }
    }, {
      name: "userLevels",
      type: "isArray",
      rules: {
        required: true,
        min: 1
      }
    }, {
      name: "userIds",
      type: "isArray",
      rules: {
        required: true,
        min: 1
      }
    }, {
      name: "eventTypes",
      type: "isArray",
      rules: {
        required: true,
        min: 1
      }
    }]),

    timePeriod: 'today',

    users: [],
    userLevels: [],
    eventTypes: [],

    companies: [],
    logs: [],
    periods: Dropdowns.periods,

    filterOn: false,
    isLoading: true,
    isLogsLoading: false,
    isModalLoading: false,
    isExportSettingsOpen: false,
    showAlert: false,
    alertType: "success",
    alertMessage: "",
    showModal: false,
    modalTitle: "Changes",
    modalData: {},
  
    modalType: "json",
    currentPage: 1,
    limit: 100,
    records: null
  };

  subscribeFunction = null;
  exportNode = null;

  componentDidMount() {
    const { controls } = this.state;
    document.addEventListener("mousedown", this.handleClickOutside, false);

    crudActions.get('v1/system/log/lookups').then(lookups => {
      this.setState({
        isLoading: false,

        companies: lookups.companies,
        eventTypes: lookups.eventTypes,
        userLevels: lookups.userLevel,
        users: lookups.users,

        controls: Object.assign(controls, {
          userLevels: lookups.userLevel.map(item => item.value),
          eventTypes: lookups.eventTypes.map(item => item.value)
        })
      }, () => {
        const { companies } = this.state;
        if (companies.length) {
          this.onValueChange(companies[0], "whiteLabelId");
        }
      });
    }).catch(
      (err) => {
        if (err && err.message) {
          this.setState({
            showAlert: true,
            alertType: "error",
            alertMessage: err.message,
            isLoading: false
          });
        }
      }
    );
  };

  handleClickOutside = (event) => {
    if (this.exportNode && this.exportNode.contains(event.target)) {
      this.setState({
        isSortSettingsOpen: false
      });

      return;
    }

    this.setState({
      isExportSettingsOpen: false
    });
  };

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, false);
    if (this.subscribeFunction) {
      this.subscribeFunction();
    }
  };

  onValueChange = (event, field) => {
    const { controls } = this.state;
    if (!event) {
      controls[field] = event;
    } else if (event.target) {
      controls[field] = event.target.value;
    } else if (event.value) {
      controls[field] = event.value;
    } else {
      controls[field] = event.map(elem => elem.value);
    }

    if (field === "whiteLabelId" || field === "userLevels") {
      const wlUsers = this.getFilteredUsers(controls.whiteLabelId, controls.userLevels);
      controls.userIds = wlUsers.map(item => item.value);
    }

    this.setState({
      controls
    });
  };

  tableColumns = () => {
    const columns = [{
      value: "actionDate",
      label: "Timestamp"
    }, {
      value: "eventType",
      label: "Event type"
    }, {
      value: "user",
      label: "User"
    }, {
      value: "role",
      label: "User role"
    }, {
      value: "ip",
      label: "IP Address"
    }, {
      value: "changes",
      label: "View changes",
      centerAligned: true,
      className: "open-modal-btn",
      buttonLabel: "View",
      isButton: true,
      triggerEvent: (data) => this.onOpenModalClick(data),
      isButtonDisabled: () => { return false; }
    }];

    return columns;
  };

  onExportOpen = () => {
    this.setState({
      isExportSettingsOpen: !this.state.isExportSettingsOpen
    });
  };

  mapSelectedItems = (type, lookups) => {
    const field = this.state.controls[type];
    const lookup = this.state[lookups];
    return field.map(elem => {
      return lookup.find(lookupElem => elem === lookupElem.value);
    });
  };

  getFilteredUsers = (wlId, userLevels) => {
    const { controls, users } = this.state;

    if (!controls.whiteLabelId) return [];

    return users.filter(user => (user.companyId === wlId || user.companyId === 0) && userLevels.indexOf(user.levelId.toString()) > -1);
  };

  getPeriod(periodType) {
    const timeZoneOffset = "00:00";
    const moment = Datetime.moment;

    switch (periodType) {
    case 1: // yesterday
      return {
        from: moment().utc().utcOffset(timeZoneOffset).subtract(1, 'day').hour(0).minute(0).second(0),
        to: moment().utc().utcOffset(timeZoneOffset).subtract(1, 'day').hour(23).minute(59).second(59),
        id: periodType
      };
    case 2: // this month
      return {
        from: moment().utc().utcOffset(timeZoneOffset).year(moment().utc().utcOffset(timeZoneOffset).year()).month(moment().utc().utcOffset(timeZoneOffset).month()).date(1).hour(0).minute(0).second(0),
        to: moment().utc().utcOffset(timeZoneOffset).hour(23).minute(59).second(59),
        id: periodType
      };
    case 3: // last month
      return {
        from: moment().utc().utcOffset(timeZoneOffset).year(moment().utc().utcOffset(timeZoneOffset).year()).month(moment().utc().utcOffset(timeZoneOffset).month() - 1).date(1).hour(0).minute(0).second(0),
        to: moment().utc().utcOffset(timeZoneOffset).year(moment().utc().utcOffset(timeZoneOffset).year()).month(moment().utc().utcOffset(timeZoneOffset).month() - 1)
          .date(moment().utc().utcOffset(timeZoneOffset).month(moment().utc().utcOffset(timeZoneOffset).month() - 1).daysInMonth()).hour(23).minute(59).second(59),
        id: periodType
      };
    case 4: // this year
      return {
        from: moment().utc().utcOffset(timeZoneOffset).year(moment().utc().utcOffset(timeZoneOffset).year()).month(0).date(1).hour(0).minute(0).second(0),
        to: moment().utc().utcOffset(timeZoneOffset).hour(23).minute(59).second(59),
        id: periodType
      };
    case 5: // last year
      return {
        from: moment().utc().utcOffset(timeZoneOffset).year(moment().utc().utcOffset(timeZoneOffset).year() - 1).month(0).date(1).hour(0).minute(0).second(0),
        to: moment().utc().utcOffset(timeZoneOffset).year(moment().utc().utcOffset(timeZoneOffset).year() - 1).month(11).date(31).hour(23).minute(59).second(59),
        id: periodType
      };
    case 6: // last hour
      return {
        from: moment().utc().utcOffset(timeZoneOffset).subtract(1, 'hour'),
        to: moment().utc().utcOffset(timeZoneOffset),
        id: periodType
      };
    case 7: // last 30 minutes
      return {
        from: moment().utc().utcOffset(timeZoneOffset).subtract(30, 'minutes'),
        to: moment().utc().utcOffset(timeZoneOffset),
        id: periodType
      };
    case 9: // last 15 minutes
      return {
        from: moment().utc().utcOffset(timeZoneOffset).subtract(15, 'minutes'),
        to: moment().utc().utcOffset(timeZoneOffset),
        id: periodType
      };
    case 0: // today
    default: // custom period
      return {
        from: moment().utc().utcOffset(timeZoneOffset).hour(0).minute(0).second(0),
        to: moment().utc().utcOffset(timeZoneOffset),
        id: periodType
      };
    }
  };

  onSelectPeriodChange = (value, id) => {
    const { controls } = this.state;

    value = value === null ? {
      id: 0,
      value: 'today',
      label: 'Today'
    } : value;
    
    const period = this.getPeriod(value.id);

    this.setState({
      timePeriod: value.value,
      controls: Object.assign(controls, {
        fromDate: period.from,
        toDate: period.to
      }),
      periodId: value.id
    });
  };

  handleDateChange = (event, fieldId) => {
    const { controls } = this.state;

    controls[fieldId] = event;

    this.setState({
      controls: controls
    });
  };

  isDateValid = (current, state) => {
    const { controls } = this.state;
    const fromDate = controls.fromDate;
    const toDate = controls.toDate;

    const yesterday = Datetime.moment().utc().subtract(1, 'days');

    if (state === 'fromDate') {
      return Datetime.moment(current).isBefore(!fromDate ? yesterday : toDate);
    }

    return Datetime.moment(current).isAfter(!toDate ? yesterday : Datetime.moment(fromDate).subtract(1, 'days'));
  };

  handleChangePage = (event) => {
    const { currentPage } = this.state;
    let pageNum = currentPage;

    if (event.target.getAttribute('data-testid') === "NavigateBeforeIcon"
      || event.target.getAttribute('aria-label') === "Go to previous page") {
      pageNum--;
    } else if (event.target.getAttribute('data-testid') === "NavigateNextIcon"
      || event.target.getAttribute('aria-label') === "Go to next page") {
      pageNum++;
    } else if (event.target.textContent){
      pageNum = event.target.textContent;
    }
   
    this.setState({ currentPage: pageNum }, () => {
      this.onFilterLogs('list');
    }); 
  };

  getLogs = () => {
    const { logs } = this.state;
    return logs.map(log => {
      return Object.assign({}, log, {
        user: (
          <NavLink to={ `/view-user/${this.getUserId(log.user)}` } target="_blank" rel="noopener noreferrer">
            { log.user }
          </NavLink>
        )
      });
    });
  };

  getUserId = (username) => {
    const { users } = this.state;

    if (!users) return "";

    const userData = username.split("/", 2);
    const userEmail = userData ? userData[0].trim() : "";
    const user = users.find(item => item.label === userEmail);
    return user ? user.value : "";
  };

  onFilterLogs = (type) => {
    const { controls, currentPage, limit, periodId } = this.state;
    const isControlsFormValid = controls.isFormValid();
    const period = this.getPeriod(periodId);

    if (periodId !== 8) {
      //we do not override dates for custom date picker
      controls.fromDate = period.from;
      controls.toDate = period.to;
    }

    this.setState({
      controls
    });

    if (isControlsFormValid) {
      if (type === "list") {
        this.setState({
          isLogsLoading: true
        });
      }

      let url = "v1/system/log";
      if (type === "file") {
        url += "/xlsx";
      }

      const data = Object.assign(controls.data(), {
        pageNumber: currentPage,
        limit: type === "file" ? INTEGER_MAX : limit
      });

      crudActions.post(url, data).then(response => {
        if (type === "file") {
          const from = Datetime.moment(controls.fromDate).format("YYYY-MM-DD HH:mm");
          const to = Datetime.moment(controls.toDate).format("YYYY-MM-DD HH:mm");
          response.blob().then(
            result => {
              saveAs(result, `Logs_${from}-${to}.xlsx`);
            }
          );
        }
        this.setState({
          ...(type === "list" && { logs: response.systemLogs || [] }),
          ...(type === "list" && { records: response.cnt }),
          isLogsLoading: false,
          filterOn: true
        });
      }).catch(
        (err) => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message,
              isLogsLoading: false
            });
          }
        }
      );
    }
  };

  onOpenModalClick = (data) => {
    this.setState({
      showModal: true,
      modalData: data.changes
    });
  };

  handleCloseModal = () => {
    this.setState({
      showModal: false
    });
  };

  onConfirm = () => {
    this.setState({
      showAlert: false,
      alertType: "success",
      alertMessage: ""
    });
  };

  render() {
    const {
      companies,
      controls,
      currentPage,
      eventTypes,
      filterOn,
      isExportSettingsOpen,
      isLoading,
      isLogsLoading,
      isModalLoading,
      limit,
      modalData,
      modalTitle,
      modalType,
      periods,
      records,
      showModal,
      timePeriod,
      userLevels
    } = this.state;

    const columns = this.tableColumns();
    const totalPageCount = Math.ceil(records / limit);

    if (!isExportSettingsOpen) {
      this.exportNode = null;
    }

    return (
      <Row flexGrow={ 1 } className="module generateReports logs" vertical='start'>
        <Column flexGrow={ 1 }>
          <Row className="header" flexGrow={ 1 } horizontal='space-between' vertical='center'>
            <Column>
              <Row horizontal='center' vertical='center' style={ { paddingLeft: 15 } }>
                <img src={ UserManagementIcon } alt="" style={ { marginRight: 10 } }/>
                System Logs
              </Row>
            </Column>
          </Row>
          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
            <Column flexGrow={ 1 } vertical='start' className="panel-block">
              <Panel>
                <Panel.Heading>
                  <Panel.Title>
                    System Logs
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  { isLoading ? (
                    <div style={ { width: "100%", height: "400px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                      <Spinner smallContainer={ true } />
                    </div>
                  ) : (
                    <div className="panel-content" style={ {overflow: 'unset'} }>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> White Label </label>
                          <Select
                            id="whiteLabelId"
                            name="whiteLabelId"
                            value={ controls.whiteLabelId }
                            required={ true }
                            clearable={ false }
                            onChange={ (value) => this.onValueChange(value, "whiteLabelId") }
                            options={ companies }
                          />
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> User Level </label>
                          <Multiselect
                            isError={ controls.errors.has("userLevels") }
                            disabled={ !controls.whiteLabelId }
                            selectedItems={ this.mapSelectedItems("userLevels", "userLevels") }
                            items={ userLevels }
                            type={ "userLevels" }
                            onChange={ (value) => this.onValueChange(value, "userLevels") }
                          />
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> User </label>
                          <Multiselect
                            isError={ controls.errors.has("userIds") }
                            disabled={ !controls.whiteLabelId }
                            selectedItems={ this.mapSelectedItems("userIds", "users") }
                            items={ this.getFilteredUsers(controls.whiteLabelId, controls.userLevels) }
                            type={ "userIds" }
                            onChange={ (value) => this.onValueChange(value, "userIds") }
                          />
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Event type </label>
                          <Multiselect
                            isError={ controls.errors.has("eventTypes") }
                            disabled={ !controls.whiteLabelId }
                            selectedItems={ this.mapSelectedItems("eventTypes", "eventTypes") }
                            items={ eventTypes }
                            type={ "eventTypes" }
                            onChange={ (value) => this.onValueChange(value, "eventTypes") }
                          />
                        </Column>
                      </Row>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label>Time Period</label>
                          <Select
                            id="timePeriod"
                            name="timePeriod"
                            value={ timePeriod || '' }
                            required={ true }
                            clearable={ false }
                            onChange={ (value) => this.onSelectPeriodChange(value, 'timePeriod') }
                            options={ periods }
                          />
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> From Date </label>
                          <img src={ Calendar } className="calendar-svg" alt="" />
                          <DatePicker
                            selected={ convertUTCToLocalDate(controls.fromDate) }
                            className="form-control"
                            timeInputLabel="Time:"
                            dateFormat={ `dd.MM.yyyy HH:mm` }
                            timeFormat="HH:mm"
                            showTimeSelect={ true }
                            timeIntervals={ 15 }
                            filterDate={ (event) => this.isDateValid(event, 'fromDate') }
                            disabled={ false }
                            onChange={ (date) => this.handleDateChange(convertLocalToUTCDate(date), 'fromDate') }
                          />
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> To Date </label>
                          <img src={ Calendar } className="calendar-svg" alt="" />
                          <DatePicker
                            selected={ convertUTCToLocalDate(controls.toDate) }
                            className="form-control"
                            timeInputLabel="Time:"
                            dateFormat={ `dd.MM.yyyy HH:mm` }
                            timeFormat="HH:mm"
                            showTimeSelect={ true }
                            timeIntervals={ 15 }
                            filterDate={ (event) => this.isDateValid(event, 'toDate') }
                            disabled={ false }
                            onChange={ (date) => this.handleDateChange(convertLocalToUTCDate(date), 'toDate') }
                          />
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label/>
                          <Button
                            type="button"
                            className="btn defaultBtn"
                            onClick={ (e) => this.onFilterLogs('list') }
                          >
                            SEARCH
                          </Button>
                        </Column>                 
                      </Row>
                    </div>
                  )}
                </Panel.Body>
              </Panel>

              { filterOn && (
                <>
                  <Panel>
                    <Panel.Heading>
                      <Panel.Title>
                        Filtered Logs
                      </Panel.Title>
                      <div className="settings-panel">
                        <span className="setting" style={ { float: "right", paddingRight: "10px" } } onClick={ () => this.onExportOpen() }>
                          <svg className="setting-icon" viewBox="0 0 32 32">
                            <g>
                              <path clipRule="evenodd" d="M8.624,21.336h2.015c1.402-7.953,8.329-14,16.684-14   c0.35,0,0.683,0.003,1.019,0.006l-3.664,3.663c-0.391,0.391-0.391,1.023,0,1.414c0.195,0.195,0.451,0.293,0.707,0.293   c0.256,0,0.512-0.098,0.707-0.293L32,6.356l-5.907-6.063c-0.391-0.391-1.023-0.391-1.414,0c-0.391,0.391-0.391,1.023,0,1.414   l3.631,3.631c-0.318-0.001-0.62-0.003-0.945-0.003C17.895,5.336,10.066,12.271,8.624,21.336z" fillRule="evenodd" />
                              <path clipRule="evenodd" d="M29,15c-0.552,0-1,0.448-1,1v14H2V10h9c0.552,0,1-0.448,1-1   c0-0.552-0.448-1-1-1h-0.03H2c-1.104,0-2,0.896-2,2v20c0,1.104,0.896,2,2,2h26c1.104,0,2-0.896,2-2V16C30,15.448,29.552,15,29,15z" fillRule="evenodd" />
                            </g>
                          </svg>
                          <span className="text"> Export </span>
                        </span>
                      </div>
                      {isExportSettingsOpen && <div ref={ node => this.exportNode = node } className="export-settings">
                        <p className="export-setting-item" onClick={ () => this.onFilterLogs('file') }> Export to Excel </p>
                      </div>
                      }
                    </Panel.Heading>
                    <Panel.Body>
                      {isLogsLoading ? (
                        <div style={ { width: "100%", height: "400px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                          <Spinner smallContainer={ true } />
                        </div>
                      ) : (
                        <>
                          <div className="logs-table">
                            <Table
                              columns={ columns }
                              data={ this.getLogs() }
                              isStriped={ true }
                              useArrow={ false }
                            />
                          </div>
                          {totalPageCount > 0 && (
                            <Panel style={ { marginTop : "-13px", borderColor : "white", maxHeight: "105px", minHeight: "100px" } }>
                              <Panel.Body>
                                <div className="pagination">
                                  <Pagination 
                                    count={ totalPageCount } 
                                    variant="outlined" 
                                    shape="rounded" 
                                    page={ Number(currentPage) }
                                    onClick= { this.handleChangePage } 
                                  />
                                </div>
                              </Panel.Body>
                            </Panel>
                          )}
                        </>
                      )}
                    </Panel.Body>
                  </Panel>
                </>
              )}
            </Column>
          </Row>
        </Column>

        <Modal
          title={ modalTitle }
          showModal={ showModal }
          modalType={ modalType }
          data={ modalData }
          isLoading={ isModalLoading }
          handleClose={ this.handleCloseModal }
        />
      </Row>
    );
  }
}

export default Logs;
