import React, { Component } from "react";
import { Column, Row } from "simple-flexbox";

import Alert from "@sm/components/custom/Alert";
import Form from "@sm/core/Form";
import Select from "@sm/components/custom/Select";
import { crudActions } from "@sm/services/crudActions";

class Multiselect extends Component {
  state = {
    mainForm: new Form(
      {
        fromAmount: "",
        toAmount: "",
        selectedPeriod: "",
      },
      [
        {
          name: "fromAmount",
          type: "isNumber",
          rules: {
            required: true,
          },
        },
        {
          name: "toAmount",
          type: "isNumber",
          rules: {
            required: true,
          },
        },
        {
          name: "selectedPeriod",
          type: "isString",
          rules: {
            required: true,
          },
        },
      ]
    ),
    selectedItems: [],
    temporarySelectedItem: [],
    showAlert: false,
    alertType: "success",
    alertMessage: "",
    newRowClicked: false,
    lookups: [],
    clientMidSettingsId: "",
    fromAmount: "",
    toAmount: "",
    values: [],
    selectedPeriod: "",
    clickedMap: [],
    dataItems: [],
    fakeId: "",
    invalidFromAmount: false,
    invalidToAmount: false,
    invalidFields: [],
    submitted: false,
    errorsMap: {},
    inputErrorsMap: [],
    checkButtonCLick: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.checkButtonCLick !== state.checkButtonCLick) {
      return {
        checkButtonCLick: props.checkButtonCLick,
      };
    }
    return null;
  }

  componentDidMount() {
    const { selectedItems } = this.props;
    if (selectedItems) {
      this.setState({
        selectedItems: selectedItems,
      });
      const props = this.props;
      const indexOfCurrentElem = Object.values(props.items[1]);
      const currentElem =
        props.items[0].tableElem.merchantSettingsRows[indexOfCurrentElem];
      let dataItems = [];
      dataItems = selectedItems.filter(
        (elem) => elem.clientMidSettingsId === currentElem.id
      );
      dataItems.forEach(
        (elem) =>
          (elem.values =
            elem.values instanceof Map
              ? elem.values
              : new Map(Object.entries(elem.values)))
      );

      const dataItemsMap = dataItems.map((elem) => {
        const mapInMap = {};
        mapInMap["id"] = elem.id;
        const valuesMap = new Map();
        valuesMap.set("fromAmount", false);
        valuesMap.set("toAmount", false);
        valuesMap.set("period", false);
        elem.values.forEach((value, key) => {
          valuesMap.set(key, false);
        });
        mapInMap.valuesMap = valuesMap;
        return mapInMap;
      });
      const { inputErrorsMap } = this.state;

      selectedItems.forEach((elem) => {
        const tmpElem = {};
        tmpElem["id"] = elem.id;
        const tmpMap = new Map();
        tmpMap.set("toAmount", true);
        tmpMap.set("fromAmount", true);
        tmpMap.set("period", true);
        if (elem.values instanceof Map) {
          const arrObjects = Object.fromEntries(elem.values);
          elem.values = Object.assign({}, arrObjects);
        }
        Object.keys(elem.values).forEach((item) => {
          tmpMap.set(item, true);
        });
        tmpElem["row"] = tmpMap;
        inputErrorsMap.push(tmpElem);
      });

      this.setState({
        clickedMap: dataItemsMap,
        dataItems: dataItems,
        fakeId: "fakeId1",
        inputErrorsMap: inputErrorsMap,
      });
    }
    Promise.all([crudActions.get("v1/adminpanel/lookups")]).then((data) => {
      const lookups = data[0];
      this.setState({
        lookups: lookups,
        errorsMap: {},
      });
    });
  }

  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (oldProps.selectedItems.length !== newProps.selectedItems.length) {
      this.setState({
        selectedItems: newProps.selectedItems,
        temporarySelectedItem: [],
        clientMidSettingsId: newProps.id,
        fromAmount: "",
        toAmount: "",
        values: [],
      });
    }

    if (oldProps.update !== newProps.update) {
      this.setState({
        newRowClicked: false,
      });
    }
  }

  onTemporaryItemChange = (sendItem) => {
    const { temporarySelectedItem } = this.state;
    this.props.onTemporaryChange(sendItem || temporarySelectedItem);
  };

  onInputItemChange = (event, fieldName) => {
    const { temporarySelectedItem, values, errorsMap } = this.state;

    if (fieldName === "toAmount" && Object.keys(errorsMap).length > 0) {
      errorsMap[fieldName] = false;
      errorsMap["fromAmount"] = false;
    }

    if (fieldName === "fromAmount" && Object.keys(errorsMap).length > 0) {
      errorsMap[fieldName] = false;
      errorsMap["toAmount"] = false;
    }
    if (fieldName === "period" && Object.keys(errorsMap).length > 0) {
      errorsMap[fieldName] = false;
    }

    if (
      fieldName !== "fromAmount" ||
      fieldName !== "toAmount" ||
      fieldName !== "period"
    ) {
      errorsMap[fieldName] = false;
    }

    let value = "";
    const indexOfCurrentElem = Object.values(this.props.items[1]);
    if (fieldName !== "period") {
      value = event.target.value.trim();
    } else {
      value = event.value;
    }

    const clientMidSettingsId =
      this.props.items[0].tableElem.merchantSettingsRows[indexOfCurrentElem].id;

    if (
      fieldName !== "fromAmount" &&
      fieldName !== "toAmount" &&
      fieldName !== "period"
    ) {
      values[fieldName] = value;
      this.setState({
        values: values,
      });
    }
    if (
      fieldName === "fromAmount" ||
      fieldName === "toAmount" ||
      fieldName === "period"
    ) {
      temporarySelectedItem[fieldName] = value;
    }

    temporarySelectedItem["values"] = new Map(Object.entries(values));

    temporarySelectedItem["clientMidSettingsId"] = clientMidSettingsId;

    this.setState({
      temporarySelectedItem: temporarySelectedItem,
      showAlert: false,
      alertType: "error",
      alertMessage: "",
      errorsMap: errorsMap,
    });
  };

  onAddItemChange = () => {
    const {
      selectedItems,
      temporarySelectedItem,
      newRowClicked,
      clickedMap,
      fakeId,
      inputErrorsMap,
    } = this.state;
    let isFormValid = true;
    if (
      temporarySelectedItem.hasOwnProperty("values") ||
      temporarySelectedItem.toAmount ||
      temporarySelectedItem.fromAmount
    ) {
      isFormValid = this.checkValidation(temporarySelectedItem);
    }
    if (!isFormValid) {
      this.setState({
        showAlert: true,
        alertType: "error",
        alertMessage: "Values in some fields are invalid. Please, fix them to continue.",
      });
      return;
    }
    let generatedFakeId = parseInt(fakeId.replace(/[A-Za-z]/g, ""));
    ++generatedFakeId;
    generatedFakeId = "fakeId" + generatedFakeId;
    if (!newRowClicked && selectedItems.length > 0) {
      this.setState({
        newRowClicked: true,
      });

      return;
    }

    if (!temporarySelectedItem) {
      return;
    }

    const mapInMap = {};

    mapInMap["id"] = generatedFakeId;

    const valuesMap = new Map();
    valuesMap.set("fromAmount", false);
    valuesMap.set("toAmount", false);
    valuesMap.set("period", false);

    if (temporarySelectedItem.hasOwnProperty("values")) {
      temporarySelectedItem.values.forEach((value, key) => {
        valuesMap.set(key, false);
      });
    }

    mapInMap.valuesMap = valuesMap;

    clickedMap.push(mapInMap);

    temporarySelectedItem["id"] = generatedFakeId;

    const mapInInputErorrsMap = {};

    mapInInputErorrsMap["id"] = generatedFakeId;

    const valuesErorrsMap = new Map();
    valuesErorrsMap.set("fromAmount", true);
    valuesErorrsMap.set("toAmount", true);
    valuesErorrsMap.set("period", true);

    if (temporarySelectedItem.hasOwnProperty("values")) {
      temporarySelectedItem.values.forEach((value, key) => {
        valuesErorrsMap.set(key, true);
      });
    }

    mapInInputErorrsMap.row = valuesErorrsMap;
    inputErrorsMap.push(mapInInputErorrsMap);

    selectedItems.push(Object.assign({}, temporarySelectedItem));
    selectedItems
      .filter(
        (e) =>
          e.clientMidSettingsId === temporarySelectedItem.clientMidSettingsId
      )
      .forEach((currentElem) => {
        currentElem.period = temporarySelectedItem.period;
      });

    this.props.onChange(selectedItems, inputErrorsMap);
    this.onTemporaryItemChange({});
    this.setState({
      selectedItems: selectedItems,
      temporarySelectedItem: {},
      showAlert: false,
      alertType: "error",
      alertMessage: "",
      newRowClicked: true,
      clickedMap: clickedMap,
      fakeId: generatedFakeId,
      values: [],
    });
    delete temporarySelectedItem["values"];
  };

  onDeleteItemChange = (type) => {
    const { selectedItems, inputErrorsMap } = this.state;
    if (!type) {
      this.setState({
        newRowClicked: false,
        temporarySelectedItem: {},
        showAlert: false,
        alertType: "error",
        alertMessage: "",
      });

      return;
    }

    const deleteIndex = selectedItems.findIndex((elem) => elem.id === type);
    const deleteIndexForErrorMap = inputErrorsMap.findIndex(
      (elem) => elem.id === type
    );
    selectedItems.splice(deleteIndex, 1);
    inputErrorsMap.splice(deleteIndexForErrorMap, 1);
    this.props.onChange(selectedItems, inputErrorsMap);

    this.setState({
      selectedItems: selectedItems,
    });
  };

  onTableDataClick = (id, fieldType, tableType) => {
    const { clickedMap } = this.state;
    const clickMapChange = clickedMap;
    const rowById = clickMapChange.find((elem) => elem.id === id);
    rowById.valuesMap.set(fieldType, true);
    this.setState(
      {
        clickedMap: clickMapChange,
      },
      () => {
        const input = this[tableType][id][fieldType];
        if (input) {
          input.focus();
        }
      }
    );
  };

  onTableDataBlur = (id, fieldType) => {
    const { clickedMap } = this.state;

    const clickMapChange = clickedMap;
    const rowById = clickMapChange.find((elem) => elem.id === id);
    rowById.valuesMap.set(fieldType, false);
    this.setState({
      clickedMap: clickMapChange,
    });
  };

  updateSelectedItems = (id, fieldName, event) => {
    const { selectedItems, dataItems, inputErrorsMap } = this.state;
    const updateSelectedItems = selectedItems;
    if (fieldName === "toAmount" || fieldName === "fromAmount") {
      updateSelectedItems.find((elem) => elem.id === id)[fieldName] =
        parseFloat(event.target.value);
    } else if (fieldName === "period") {
      dataItems.forEach((element) => {
        updateSelectedItems.find((elem) => elem.id === element.id)[fieldName] =
          event.value;
      });
    } else {
      updateSelectedItems
        .find((elem) => elem.id === id)
        ["values"].set(fieldName, parseFloat(event.target.value));
    }

    this.setState({
      selectedItems: updateSelectedItems,
      selectedPeriod: event.value,
    });

    if (fieldName === "toAmount") {
      inputErrorsMap.find((elem) => elem.id === id)["row"].set(fieldName, true);
      inputErrorsMap
        .find((elem) => elem.id === id)
        ["row"].set("fromAmount", true);
    }

    if (fieldName === "fromAmount") {
      inputErrorsMap.find((elem) => elem.id === id)["row"].set(fieldName, true);
      inputErrorsMap
        .find((elem) => elem.id === id)
        ["row"].set("toAmount", true);
    }

    if (
      fieldName !== "fromAmount" ||
      fieldName !== "toAmount" ||
      fieldName !== "period"
    ) {
      inputErrorsMap.find((elem) => elem.id === id)["row"].set(fieldName, true);
    }
    this.props.onChange(selectedItems, inputErrorsMap);
  };

  greaterValue = (value) => {
    const greaterValue = parseInt(value) + 1;
    return greaterValue;
  };

  checkValidation = (temporarySelectedItem) => {
    const props = this.props;
    const indexOfCurrentElem = Object.values(props.items[1]);
    const currentElem =
      props.items[0].tableElem.merchantSettingsRows[indexOfCurrentElem];
    const headers = currentElem.processingFeeHeaders;
    let { errorsMap } = this.state;
    let isValid = true;
    errorsMap = {};

    errorsMap["toAmount"] =
      !temporarySelectedItem.toAmount ||
      parseInt(temporarySelectedItem.toAmount) <
        parseInt(temporarySelectedItem.fromAmount) ||
      temporarySelectedItem.toAmount === temporarySelectedItem.fromAmount;

    errorsMap["fromAmount"] =
      !temporarySelectedItem.fromAmount ||
      parseInt(temporarySelectedItem.toAmount) <
        parseInt(temporarySelectedItem.fromAmount) ||
      temporarySelectedItem.toAmount === temporarySelectedItem.fromAmount;

    errorsMap["period"] = !temporarySelectedItem.period;

    if (temporarySelectedItem.hasOwnProperty("values")) {
      // eslint-disable-next-line array-callback-return
      headers.some((header) => {
        if (
          !temporarySelectedItem["values"].has(header.name) ||
          temporarySelectedItem["values"].get(header.name) === ""
        ) {
          errorsMap[header.name] = true;
        }
        if (errorsMap[header.name]) {
          isValid = false;
        }
      });
    }

    if (
      errorsMap["toAmount"] ||
      errorsMap["fromAmount"] ||
      errorsMap["period"]
    ) {
      isValid = false;
    }

    this.setState({
      errorsMap,
    });

    return isValid;
  };

  getValue = (value) => {
    if (value === 0) {
      return 0;
    } else if (!value) {
      return "";
    }

    return value;
  };

  setRef = (id, fieldValue, ref, tableType) => {
    if (!this[tableType]) {
      this[tableType] = {};
    }

    if (!this[tableType][id]) {
      this[tableType][id] = {};
    }

    this[tableType][id][fieldValue] = ref;
  };

  render() {
    const {
      selectedItems,
      temporarySelectedItem,
      newRowClicked,
      errorsMap,
      lookups,
      clickedMap,
      selectedPeriod,
      inputErrorsMap,
      checkButtonCLick,
      showAlert,
      alertType,
      alertMessage
    } = this.state;
    const props = this.props;

    if (checkButtonCLick) {
      const checkInvalidInput = inputErrorsMap.filter((elem) => {
        let result = false;
        elem.row.forEach((value, key) => {
          if (value === false) {
            result = true;
          }
        });
        return result;
      });
      checkInvalidInput.forEach((e) => {
        const findClickedElem = clickedMap.find((el) => el.id === e.id);
        if (findClickedElem) {
          e.row.forEach((value, key) => {
            findClickedElem.valuesMap.set(key, !value);
          });
        }
      });
    }

    const indexOfCurrentElem = Object.values(props.items[1]);
    const currentElem =
      props.items[0].tableElem.merchantSettingsRows[indexOfCurrentElem];
    const countHeaders = Object.keys(props.items[0].tableElem.fields).length;
    const countInputHeaders = Object.keys(
      currentElem.processingFeeHeaders
    ).length;
    const emptyHeaders = countHeaders - countInputHeaders + 1;
    const periods = lookups.processingFeePeriods;
    let dataItems = [];
    if (selectedItems) {
      dataItems = selectedItems.filter(
        (elem) => elem.clientMidSettingsId === currentElem.id
      );
      dataItems.forEach(
        (elem) =>
          (elem.values =
            elem.values instanceof Map
              ? elem.values
              : new Map(Object.entries(elem.values)))
      );
    }
    return (
      <Column
        className="manual-data-input"
        flexGrow={ 1 }
        vertical="start"
        alignSelf="start"
      >
        <Column
          className="manual-data-input-body"
          flexGrow={ 1 }
          vertical="start"
          alignSelf="start"
        >
          {dataItems.map((elem, i) => {
            return (
              <Row
                key={ elem.value }
                flexGrow={ 1 }
                horizontal="start"
                className="selected-items"
              >
                <div className="settings-table-wrapper">
                  <table className="table table-striped mids-table">
                    {i === 0 && (
                      <thead>
                        <tr>
                          <th> From Amount </th>
                          <th> To Amount </th>
                          {currentElem.processingFeeHeaders.map((column) => {
                            return <th key={ column.name }> {column.label} </th>;
                          })}
                          {[...Array(emptyHeaders)].map((e, l) => (
                            <th key={ l }></th>
                          ))}
                          <th> Period </th>
                          <th className="emptyTh"> </th>
                        </tr>
                      </thead>
                    )}
                    <tbody>
                      <td
                        onDoubleClick={ () =>
                          this.onTableDataClick(
                            elem.id,
                            "fromAmount",
                            elem.clientMidSettingsId
                          )
                        }
                        className="spanTiers"
                      >
                        {clickedMap.find((el) => el.id === elem.id) ? (
                          clickedMap
                            .find((el) => el.id === elem.id)
                            ["valuesMap"].get("fromAmount") ? (
                              <input
                                type="number"
                                value={ this.getValue(elem["fromAmount"]) }
                                className={ `table-input " + ${
                                  inputErrorsMap[
                                    inputErrorsMap.indexOf(
                                      inputErrorsMap.find(
                                        (el) => el.id === elem.id
                                      )
                                    )
                                  ].row.get("fromAmount")
                                    ? ""
                                    : "td-error"
                                }` }
                                ref={ (input) =>
                                  this.setRef(
                                    elem.id,
                                    "fromAmount",
                                    input,
                                    elem.clientMidSettingsId
                                  )
                                }
                                onChange={ (event) =>
                                  this.updateSelectedItems(
                                    elem.id,
                                    "fromAmount",
                                    event
                                  )
                                }
                                onBlur={ () =>
                                  this.onTableDataBlur(
                                    elem.id,
                                    "fromAmount",
                                    elem.clientMidSettingsId
                                  )
                                }
                              />
                            ) : (
                              <span>{elem.fromAmount}</span>
                            )
                        ) : (
                          <span>{elem.fromAmount}</span>
                        )}
                      </td>

                      <td
                        onDoubleClick={ () =>
                          this.onTableDataClick(
                            elem.id,
                            "toAmount",
                            elem.clientMidSettingsId
                          )
                        }
                        className="spanTiers"
                      >
                        {clickedMap.find((el) => el.id === elem.id) ? (
                          clickedMap
                            .find((el) => el.id === elem.id)
                            ["valuesMap"].get("toAmount") ? (
                              <input
                                type="number"
                                value={ this.getValue(elem["toAmount"]) }
                                className={ `table-input " + ${
                                  inputErrorsMap[
                                    inputErrorsMap.indexOf(
                                      inputErrorsMap.find(
                                        (el) => el.id === elem.id
                                      )
                                    )
                                  ]["row"].get("toAmount")
                                    ? ""
                                    : "td-error"
                                }` }
                                ref={ (input) =>
                                  this.setRef(
                                    elem.id,
                                    "toAmount",
                                    input,
                                    elem.clientMidSettingsId
                                  )
                                }
                                onChange={ (event) =>
                                  this.updateSelectedItems(
                                    elem.id,
                                    "toAmount",
                                    event
                                  )
                                }
                                onBlur={ () =>
                                  this.onTableDataBlur(elem.id, "toAmount")
                                }
                              />
                            ) : (
                              <span>{elem.toAmount}</span>
                            )
                        ) : (
                          <span>{elem.toAmount}</span>
                        )}
                      </td>
                      {currentElem.processingFeeHeaders.map((column) => {
                        return (
                          <td
                            onDoubleClick={ () =>
                              this.onTableDataClick(
                                elem.id,
                                column.name,
                                elem.clientMidSettingsId
                              )
                            }
                            key={ column.name }
                            className="spanTiers"
                          >
                            {clickedMap.find((el) => el.id === elem.id) ? (
                              clickedMap
                                .find((el) => el.id === elem.id)
                                ["valuesMap"].get(column.name) ? (
                                  <input
                                    type="number"
                                    value={ this.getValue(
                                      elem["values"].get(column.name)
                                    ) }
                                    className={ `table-input " + ${
                                      inputErrorsMap[
                                        inputErrorsMap.indexOf(
                                          inputErrorsMap.find(
                                            (el) => el.id === elem.id
                                          )
                                        )
                                      ]["row"].get(column.name)
                                        ? ""
                                        : "td-error"
                                    }` }
                                    ref={ (input) =>
                                      this.setRef(
                                        elem.id,
                                        column.name,
                                        input,
                                        elem.clientMidSettingsId
                                      )
                                    }
                                    onChange={ (event) =>
                                      this.updateSelectedItems(
                                        elem.id,
                                        column.name,
                                        event
                                      )
                                    }
                                    onBlur={ () =>
                                      this.onTableDataBlur(elem.id, column.name)
                                    }
                                  />
                                ) : (
                                  <span>{elem.values.get(column.name)}</span>
                                )
                            ) : (
                              <span>{elem.values.get(column.name)}</span>
                            )}
                          </td>
                        );
                      })}
                      {[...Array(emptyHeaders)].map((e, l) => (
                        <td className="spanTiers" key={ l }></td>
                      ))}
                      <td
                        onDoubleClick={ () =>
                          this.onTableDataClick(
                            elem.id,
                            "period",
                            elem.clientMidSettingsId
                          )
                        }
                        className="spanTiers"
                      >
                        {clickedMap.find((el) => el.id === elem.id) ? (
                          clickedMap
                            .find((el) => el.id === elem.id)
                            ["valuesMap"].get("period") ? (
                              <Select
                                name="period"
                                value={ selectedPeriod || elem.period }
                                clearable={ false }
                                onChange={ (event) =>
                                  this.updateSelectedItems(
                                    elem.id,
                                    "period",
                                    event
                                  )
                                }
                                ref={ (select) =>
                                  this.setRef(
                                    elem.id,
                                    "period",
                                    select,
                                    elem.clientMidSettingsId
                                  )
                                }
                                options={ periods }
                                onBlur={ () =>
                                  this.onTableDataBlur(elem.id, "period")
                                }
                              />
                            ) : (
                              <span>
                                {" "}
                                {periods
                                  ? temporarySelectedItem.period
                                    ? periods.find(
                                      (period) =>
                                        period.value ===
                                        temporarySelectedItem.period
                                    )["label"]
                                    : selectedPeriod
                                      ? periods.find(
                                        (period) =>
                                          period.value === selectedPeriod
                                      )["label"]
                                      : periods.find(
                                        (period) => period.value === elem.period
                                      )["label"]
                                  : ""}
                              </span>
                            )
                        ) : (
                          <span>
                            {" "}
                            {periods
                              ? temporarySelectedItem.period
                                ? periods.find(
                                  (period) =>
                                    period.value ===
                                      temporarySelectedItem.period
                                )["label"]
                                : selectedPeriod
                                  ? periods.find(
                                    (period) => period.value === selectedPeriod
                                  )["label"]
                                  : periods.find(
                                    (period) => period.value === elem.period
                                  )["label"]
                              : ""}
                          </span>
                        )}
                      </td>
                      {props.editable && (
                        <td className="buttonsTd">
                          <Row
                            className="actions"
                            flexGrow={ 1 }
                            horizontal="start"
                            wrap={ true }
                            vertical="end"
                          >
                            <button
                              className="delete"
                              onClick={ () => this.onDeleteItemChange(elem.id) }
                            >
                              X
                            </button>
                            {!newRowClicked && i === dataItems.length - 1 && (
                              <button
                                className="add"
                                onClick={ () => this.onAddItemChange() }
                              >
                                <span className="plus"> + </span>
                              </button>
                            )}
                          </Row>
                        </td>
                      )}
                    </tbody>
                  </table>
                </div>
              </Row>
            );
          })}
          {(dataItems.length === 0 || newRowClicked) && props.editable && (
            <Row style={ { width: "100%" } } flexGrow={ 1 } horizontal="start">
              <Column
                className="manual-data-input-column"
                style={ { width: "100%" } }
              >
                <div className="settings-table-wrapper">
                  <table className="table table-striped mids-table">
                    {dataItems.length === 0 && (
                      <thead>
                        <tr className="headerStyle">
                          <th> From Amount </th>
                          <th> To Amount </th>
                          {currentElem.processingFeeHeaders.map((column) => {
                            return <th key={ column.name }> {column.label} </th>;
                          })}
                          {[...Array(emptyHeaders)].map((e, l) => (
                            <th key={ l }></th>
                          ))}
                          <th> Period </th>
                          <th className="emptyTh"> </th>
                        </tr>
                      </thead>
                    )}
                    <tbody>
                      <td className="tiersInputAmount">
                        {" "}
                        <input
                          type="number"
                          className={ `form-control  ${
                            errorsMap["fromAmount"] ? "td-error" : ""
                          }` }
                          value={ temporarySelectedItem.fromAmount || "" }
                          onChange={ (event) =>
                            this.onInputItemChange(event, "fromAmount")
                          }
                        />
                      </td>
                      <td className="tiersInputAmount">
                        {" "}
                        <input
                          type="number"
                          className={ `form-control  ${
                            errorsMap["toAmount"] ? "td-error" : ""
                          }` }
                          value={ temporarySelectedItem.toAmount || "" }
                          onChange={ (event) =>
                            this.onInputItemChange(event, "toAmount")
                          }
                        />
                      </td>
                      {currentElem.processingFeeHeaders.map((column) => {
                        return (
                          <td className="tiersInput" key={ column.name }>
                            <input
                              type="number"
                              className={ `form-control  ${
                                errorsMap[column.name] ? "td-error" : ""
                              }` }
                              value={
                                temporarySelectedItem.values !== undefined
                                  ? temporarySelectedItem.values[column.name]
                                  : ""
                              }
                              onChange={ (event) =>
                                this.onInputItemChange(event, column.name)
                              }
                            />
                          </td>
                        );
                      })}
                      {[...Array(emptyHeaders)].map((e, l) => (
                        <th className="tiersInput" key={ l }></th>
                      ))}
                      <td className="input-column">
                        <Select
                          name="period"
                          className={ `${errorsMap["period"] ? "td-error" : ""}` }
                          value={ temporarySelectedItem.period || "" }
                          clearable={ false }
                          onChange={ (event) =>
                            this.onInputItemChange(event, "period")
                          }
                          options={ periods }
                        />
                      </td>
                      <td className="actions">
                        {dataItems.length > 0 && (
                          <button
                            style={ { marginTop: "5px" } }
                            className="delete"
                            onClick={ () => this.onDeleteItemChange("") }
                          >
                            X
                          </button>
                        )}
                        <button
                          className="add"
                          onClick={ () => this.onAddItemChange() }
                        >
                          <span className="plus"> + </span>
                        </button>
                      </td>
                    </tbody>
                  </table>
                </div>
              </Column>
            </Row>
          )}
        </Column>

        {showAlert && (
          <Alert
            show={ showAlert }
            title={ alertType }
            type={ alertType }
            text={ alertMessage }
            confirmButtonColor="#187EED"
            onConfirm={ this.onConfirm }
          />
        )}

      </Column>
    );
  }
}

export default Multiselect;
