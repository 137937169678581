import React from 'react';
import SweetAlert2 from 'react-sweetalert2';

const Alert = ( props ) => {
  const { show, text, title, type, confirmButtonColor, onConfirm } = props;

  let buttonColor = "#187EED";
  if (type === "error") {
    buttonColor = "#DD6B55";
  } else if (confirmButtonColor) {
    buttonColor = confirmButtonColor;
  }

  return (
    <SweetAlert2
      show={ show }
      title={ title }
      icon={ type }
      confirmButtonColor={ buttonColor }
      text={ text }
      onConfirm={ onConfirm }
      onResolve={ onConfirm }
    />
  );
};

export default Alert;
